import React from "react";
import { Outlet } from "react-router-dom";
import NavigationDrawer from "../Components/NavigationDrawer";
import "../Styles/CustomStyles.css";

function DefaultLayout() {

//   useEffect(() => {
//     // if (
//     //   !(
//     //     JSON.parse(localStorage.getItem("userDetails")).email_verifed &&
//     //     JSON.parse(localStorage.getItem("userDetails")).phone_verified
//     //   )
//     // ) {
//     //   navigate("/signup/verification");
//     // }

  return (
    <div className="home__page px-8 py-5 h-screen">
      <div className="col-span-1">
        <NavigationDrawer />
      </div>
      <div className="col-[2_/_span_19] pl-7">
        <Outlet />
      </div>
    </div>
  );
}

export default DefaultLayout;
