import React from "react";
import "../Styles/CustomStyles.css";
import LandingPageHeader from "../Components/LandingPageHeader";
import main_banner from "../assets/main_banner.png";
import curved_line from "../assets/curved_line.png";
import landing_icon1 from "../assets/landing_icon1.png";
import landing_icon2 from "../assets/landing_icon2.png";
import landing_icon3 from "../assets/landing_icon3.png";
import feature_1 from "../assets/features_1.png";
import feature_2 from "../assets/features_2.png";
import feature_3 from "../assets/features_3.png";
import benefits_banner from "../assets/benefits_banner.png";
import ColoredButton from "../Components/ColoredButton";
import { CheckCircle } from "@mui/icons-material";

function Index() {
  return (
    <div className="bg-white">
      <div className="landing_page-bg pb-24">
        <LandingPageHeader />
        <section className="px-32 py-12 flex justify-between">
          <div>
            <h1 className="font-bold text-6xl leading-[73px]">
              Find, analyze, and track options trading opportunities
            </h1>
            <img src={curved_line} alt="Curved line" />
            <h1 className="w-3/4 my-7 font-medium leading-8">
              Trade Buddy: Your ultimate options trading companion. Discover,
              analyze, and track opportunities with powerful tools for smarter
              trading decisions
            </h1>
            <ColoredButton sx={{ borderRadius: "40px" }} variant="contained">
              Try free trial
            </ColoredButton>
          </div>
          <img src={main_banner} alt="Main banner" className="h-auto w-auto" />
        </section>
        <h1 className="font-bold text-4xl text-center mt-9">
          More than X teams use Trade Buddys
        </h1>
      </div>
      <section className="flex justify-between gap-20 bg-[#F9F8FE] py-14 px-20">
        <div className="self-center">
          <h1 className="font-semibold text-4xl leading-[54px]">
            How we support our customers all over the world
          </h1>
          <p className="text-[#A6A6A6] font-medium mt-10 leading-7">
            Responsive customer service, robust security measures, extensive
            knowledge base, and continuous improvement ensure exceptional
            support and protection for our customers at Trade Buddy.
          </p>
        </div>
        <div className="flex flex-col gap-5 w-full">
          <div className="flex gap-8">
            <img src={landing_icon1} alt="icon" className="h-[60px]" />
            <div>
              <h1 className="font-bold text-2xl">Advanced Options Scanning</h1>
              <p className="text-[#A6A6A6] font-medium mt-5 leading-7">
                Swiftly find and analyze potential trading opportunities based
                on volatility, volume, and option Greeks.
              </p>
            </div>
          </div>
          <div className="flex gap-8">
            <img src={landing_icon2} alt="icon" className="h-[60px]" />
            <div>
              <h1 className="font-bold text-2xl">Comprehensive analysis</h1>
              <p className="text-[#A6A6A6] font-medium mt-5 leading-7">
                In-depth insights and analysis to assess profitability and risk
                profiles of options strategies.
              </p>
            </div>
          </div>
          <div className="flex gap-8">
            <img src={landing_icon3} alt="icon" className="h-[60px]" />
            <div>
              <h1 className="font-bold text-2xl">
                Real time tracking and monitoring
              </h1>
              <p className="text-[#A6A6A6] font-medium mt-5 leading-7">
                Stay informed with up-to-date market data, option prices, and
                timely position tracking.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="landing_page_bg-2 py-20">
        <section className="px-32 pb-20">
          <div className="flex justify-between">
            <h1 className="font-bold text-4xl leading-[50px]">
              Our Features
              <br /> you can get
            </h1>
            <p className="text-[#A6A6A6] font-medium leading-8">
              We offer a variety of interesting features that you can
              <br /> help increase yor productivity at work and manage
              <br /> your project easily
            </p>
            <ColoredButton
              sx={{ borderRadius: "40px" }}
              variant="contained"
              className="h-fit self-center"
            >
              Get started
            </ColoredButton>
          </div>
          <div className="grid grid-cols-3 gap-11 mt-16">
            <div className="flex flex-col gap-6">
              <img src={feature_1} alt="Feature 1" className="h-auto w-auto" />
              <h1 className="font-semibold text-3xl">
                Target Shares like never Before
              </h1>
              <p className="leading-7 font-medium text-[#A6A6A6]">
                Target shares with precision for unbeatable trading strategies.
              </p>
            </div>
            <div className="flex flex-col gap-6">
              <img src={feature_2} alt="Feature 2" className="h-auto w-auto" />
              <h1 className="font-semibold text-3xl">Use Algorithms</h1>
              <p className="leading-7 font-medium text-[#A6A6A6]">
                Leverage prebuilt algorithms for automated trading precision and
                enhanced performance.
              </p>
            </div>
            <div className="flex flex-col gap-6">
              <img src={feature_3} alt="Feature 3" className="h-auto w-auto" />
              <h1 className="font-semibold text-3xl">Daily Analytics</h1>
              <p className="leading-7 font-medium text-[#A6A6A6]">
                Real-time insights to help traders make informed decisions based
                on market trends and performance indicators.
              </p>
            </div>
          </div>
        </section>
        <section className="px-32 flex justify-between">
          <div>
            <h1 className="font-bold text-4xl leading-[50px]">
              What Benefits Will <br /> You Get
            </h1>
            <div className="flex flex-col gap-9 mt-12">
              <div className="flex gap-5 items-center">
                <CheckCircle sx={{ color: "#643FBD" }} fontSize="small" />
                <h1 className="font-medium text-lg">
                  Enhanced Trading Strategies
                </h1>
              </div>
              <div className="flex gap-5 items-center">
                <CheckCircle sx={{ color: "#643FBD" }} fontSize="small" />
                <h1 className="font-medium text-lg">
                  Improved Decision-Making
                </h1>
              </div>
              <div className="flex gap-5 items-center">
                <CheckCircle sx={{ color: "#643FBD" }} fontSize="small" />
                <h1 className="font-medium text-lg">
                  Increased Profit Potential
                </h1>
              </div>
              <div className="flex gap-5 items-center">
                <CheckCircle sx={{ color: "#643FBD" }} fontSize="small" />
                <h1 className="font-medium text-lg">Time-Saving Efficiency</h1>
              </div>
              <div className="flex gap-5 items-center">
                <CheckCircle sx={{ color: "#643FBD" }} fontSize="small" />
                <h1 className="font-medium text-lg">
                  Access to Real-Time Market Insights
                </h1>
              </div>
            </div>
          </div>
          <div>
            <img
              src={benefits_banner}
              alt="Benefits Banner"
              className="h-auto w-auto"
            />
          </div>
        </section>
      </div>

      {/* <section>What people are saying</section>
      <section>Footer</section> */}
    </div>
  );
}

export default Index;
