import React, { useEffect, useState } from "react";
import sign_up_illustration from "../assets/sign_up_illustration.png";
import logo_temp from "../assets/logo_temp.png";
import TextInput from "../Components/TextInput";
import { Alert, Backdrop, IconButton, InputAdornment } from "@mui/material";
import {
  AccountCircle,
  Email,
  Lock,
  Phone,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import ColoredButton from "../Components/ColoredButton";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import axiosInstance from "../Configuration/axiosConfig";
import useToken from "../Components/useToken";

function SignUp() {
    const { setToken } = useToken();
  const [showPassword, setShowPassword] = useState(false);

  const [userRegistrationDetails, setUserRegistrationDetails] = useState({
    username: "",
    email: "",
    password1: "",
    password2: "",
    phone: "",
  });

  const [isError, setIsError] = useState({
    username: false,
    email: false,
    password1: false,
    password2: false,
    phone: false,
  });

  const [helperText, setHelperText] = useState({
    usernameHelperText: "",
    emailHelperText: "",
    passwordHelperText: "",
    confirmPasswordHelperText: "",
    phoneNumberHelperText: "",
  });

  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState({ msg: "", type: "" });

  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onRegisterBtnClick = async (event) => {
    event.preventDefault();
    if (
      isError.username ||
      isError.email ||
      isError.password1 ||
      isError.password2 ||
      isError.phone
    )
      return;
    else {
      setLoading(true);
      setMsg({msg: '', type: ''});
      try {
        const response = await axiosInstance.post('auth/register/', userRegistrationDetails);

        if(response.status === 201) {
            setToken({
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token
            });
            localStorage.setItem("loggedInUser", JSON.stringify(response.data.user));

            const userResponse = await axiosInstance.get('auth/user/', {
                headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("userAuthToken"))?.userToken?.accessToken}
            });

            if(userResponse.status === 200) {
                localStorage.setItem("userDetails", JSON.stringify(userResponse.data));
                setMsg({msg: "User registered successfully", type: "success"});
            }

            setTimeout(() => {
                navigate("/home", {replace: true});
            }, 1500);
        }
      } catch(err) {
        let msg = '';
        if(err.request.status === 404)
            msg = err.message;
        else if(err.request.status === 401 || err.request.status === 400)
            msg = Object.values(err.response.data)[0];
        setMsg({ msg: msg, type: "error" });
      } finally {
        setLoading(false);
      }
    }
  };

  const onUserInputChange = (event) => {
    const { name, value } = event.target;

    setUserRegistrationDetails((prevFormData) => {
      return { ...prevFormData, [name]: value };
    });
  };

  const validationController = (event) => {
    const { name, value } = event.target;

    setIsError((prevData) => {
      return { ...prevData, [name]: validateUserInput(name, value) };
    });
  };

  const validateUserInput = (name, value) => {
    // if (name === "email") {
    //   if (value === "") {
    //     setHelperText((prevData) => {
    //       return { ...prevData, emailHelperText: "Email is required" };
    //     });
    //     return true;
    //   } else if (!value.match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/)) {
    //     setHelperText((prevData) => {
    //       return { ...prevData, emailHelperText: "Invalid email format" };
    //     });
    //     return true;
    //   }
    //   return false;
    // }

    if (name === "username") {
      if (value === "") {
        setHelperText((prevData) => {
          return { ...prevData, usernameHelperText: "Username is required" };
        });
        return true;
      } else if (
        !value.match(/^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/)
      ) {
        setHelperText((prevData) => {
          return { ...prevData, usernameHelperText: "Invalid username" };
        });
        return true;
      }
      return false;
    }

    if (name === "password1") {
      if (value === "") {
        setHelperText((prevData) => {
          return { ...prevData, passwordHelperText: "Password is required" };
        });
        return true;
      } else if (!value.match(/[^ ]{6}/)) {
        setHelperText((prevData) => {
          return {
            ...prevData,
            passwordHelperText: "Password must have at least 6 characters",
          };
        });
        return true;
      }
      return false;
    }

    if (name === "password2") {
      if (value !== userRegistrationDetails.password1) {
        setHelperText((prevData) => {
          return {
            ...prevData,
            confirmPasswordHelperText:
              "Password and confirm password does not match",
          };
        });
        return true;
      }
      return false;
    }

    if (name === "phone") {
      if (value === "") {
        setHelperText((prevData) => {
          return {
            ...prevData,
            phoneNumberHelperText: "Phone number is required",
          };
        });
        return true;
      } else if (!value.match(/^\+?[1-9][0-9]{7,14}$/)) {
        setHelperText((prevData) => {
          return { ...prevData, phoneNumberHelperText: "Invalid phone number" };
        });
        return true;
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    setIsError(prevData => {
        return {...prevData, username: false}
    });
    setHelperText(prevData => {
        return {...prevData, usernameHelperText: ""}
    });
  }, [userRegistrationDetails.username])
  
  useEffect(() => {
    setIsError(prevData => {
        return {...prevData, username: false}
    });
    setHelperText(prevData => {
        return {...prevData, usernameHelperText: ""}
    });
  }, [userRegistrationDetails.username])

  useEffect(() => {
    setIsError(prevData => {
        return {...prevData, password1: false}
    });
    setHelperText(prevData => {
        return {...prevData, passwordHelperText: ""}
    });
  }, [userRegistrationDetails.password1])
  
  useEffect(() => {
    setIsError(prevData => {
        return {...prevData, password2: false}
    });
    setHelperText(prevData => {
        return {...prevData, confirmPasswordHelperText: ""}
    });
  }, [userRegistrationDetails.password2])
  
  useEffect(() => {
    setIsError(prevData => {
        return {...prevData, phone: false}
    });
    setHelperText(prevData => {
        return {...prevData, phoneNumberHelperText: ""}
    });
  }, [userRegistrationDetails.phone])

  return (
    <div className="grid grid-cols-1 md:grid-cols-4">
      <div className="h-screen flex flex-col col-span-1 md:col-span-2 bg-[#F3F5F9]">
        <div className="flex items-center px-6 pt-5">
          <img src={logo_temp} alt="logo" className="mr-5" />
          <h1 className="text-xl md:text-2xl font-light">Company name</h1>
        </div>
        <div className="hidden col-span-2 md:flex flex-auto justify-center items-center">
          <img src={sign_up_illustration} alt="Sign up illustration" />
        </div>
      </div>

      <div className="flex h-screen col-span-1 md:col-span-2 justify-center items-center">
        <div className="w-4/5 lg:w-2/4 flex flex-col items-center">
          <h1 className="text-3xl md:text-3xl lg:text-5xl text-center font-semibold mb-2.5">
            Welcome
          </h1>
          <p className="text-sm md:text-base font-light text-[#333333]">
            To register, please enter your details
          </p>
          <form className="w-full px-5 mt-6" onSubmit={onRegisterBtnClick}>
            {msg.msg ? <Alert severity={msg.type}>{msg.msg}</Alert> : null}
            <div className="flex flex-col mb-5">
              <label htmlFor="username" className="text-base md:text-xl mb-2">
                Username<span className="text-red-700">*</span>
              </label>
              <TextInput
                value={userRegistrationDetails.username}
                error={isError.username}
                autoFocus
                name="username"
                placeholder="Enter username"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                helperText={
                  isError.username ? helperText.usernameHelperText : ""
                }
                onChange={onUserInputChange}
                onBlur={validationController}
              />
            </div>
            <div className="flex flex-col mb-5">
              <label htmlFor="email" className="text-base md:text-xl mb-2">
                Email<span className="text-red-700">*</span>
              </label>
              <TextInput
                value={userRegistrationDetails.email}
                error={isError.email}
                name="email"
                placeholder="Enter email"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                helperText={isError.email ? helperText.emailHelperText : ""}
                onChange={onUserInputChange}
                onBlur={validationController}
              />
            </div>
            <div className="flex flex-col mb-5">
              <label htmlFor="password1" className="text-base md:text-xl mb-2">
                Password<span className="text-red-700">*</span>{" "}
                <span className="text-sm text-gray-600">
                  (6 or more characters)
                </span>
              </label>
              <TextInput
                value={userRegistrationDetails.password1}
                error={isError.password1}
                type={showPassword ? "text" : "password"}
                name="password1"
                autoComplete="off"
                placeholder="Enter password"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                onChange={onUserInputChange}
                helperText={
                  isError.password1 ? helperText.passwordHelperText : ""
                }
                onBlur={validationController}
              />
            </div>
            <div className="flex flex-col mb-5">
              <label
                htmlFor="password2"
                className="text-base md:text-xl mb-2"
              >
                Confirm Password<span className="text-red-700">*</span>
              </label>
              <TextInput
                value={userRegistrationDetails.password2}
                error={isError.password2}
                type="password"
                name="password2"
                autoComplete="off"
                placeholder="Re-enter password"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                onChange={onUserInputChange}
                helperText={
                  isError.password2
                    ? helperText.confirmPasswordHelperText
                    : ""
                }
                onBlur={validationController}
              />
            </div>
            <div className="flex flex-col mb-5">
              <label
                htmlFor="phone"
                className="text-base md:text-xl mb-2"
              >
                Phone Number<span className="text-red-700">*</span>
              </label>
              <TextInput
                name="phone"
                value={userRegistrationDetails.phone}
                error={isError.phone}
                placeholder="Enter Phone Number"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                onChange={onUserInputChange}
                helperText={
                  isError.phone ? helperText.phoneNumberHelperText : ""
                }
                onBlur={validationController}
              />
            </div>
            <ColoredButton
              type="submit"
              fullWidth
              className="hover:scale-105"
              variant="contained"
              size="small"
              onClick={onRegisterBtnClick}
            >
              Register Now
            </ColoredButton>
          </form>
        </div>
      </div>
      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
        <Loading />
      </Backdrop>
    </div>
  );
}

export default SignUp;
