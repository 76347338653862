import React, { Fragment, useRef, useState } from "react";
import CustomStepper from "../Components/Stepper";
import ColoredButton from "../Components/ColoredButton";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import SpecifyStrategyDetails from "../Components/SpecifyStrategyDetails";
import ConfirmStrategy from "../Components/ConfirmStrategy";
import AddStrategies from "../Components/AddStrategies";
import instrumentData from "../Components/InstrumentsData";
import { Alert, Backdrop, Snackbar } from "@mui/material";
import Loading from "../Components/Loading";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../Configuration/axiosConfig";
import moment from "moment";

function AddStrategy() {
  const [activeStep, setActiveStep] = useState(0);

  const [strategyDetails, setStrategyDetails] = useState({
    strategyName: "",
    instrument: null,
    segment: "options",
    expiry: null,
    strategySelected: "Select a strategy",
    positions: [],
    lots: 1,
    execution: "buy",
    delivery: "nrml",
    targetDelta: 0,
    stopLossDelta: 0,
    isop: 0,
    target: 0,
    stopLoss: 0,
  });

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [displayMsg, setDisplayMsg] = useState({
    type: "success",
    msg: "",
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const optionInstrumentsList = useRef(
    instrumentData
      .getData("NSEFO")
      .filter((item) => item[5] === "OPTIDX" || item[5] === "OPTSTK")
  );

  const handleNext = async () => {
    if (activeStep === 0) {
      if (
        strategyDetails.strategyName === "" ||
        strategyDetails.instrument === null ||
        strategyDetails.expiry === null
      ) {
        setSnackbarMsg("error", "Please fill all the required fields");

        return;
      }
    } else if (activeStep === 1) {
      if (
        strategyDetails.strategySelected === "Select a strategy" ||
        strategyDetails.positions.length === 0
      ) {
        setSnackbarMsg("error", "Select a strategy and configure its legs");
        return;
      } else if (
        strategyDetails.positions.some(
          (position) => position.entryPrice === "N/A"
        )
      ) {
        setSnackbarMsg(
          "error",
          "The strike price selected is not tradeable indicated by N/A value."
        );
        return;
      }
    } else if (activeStep === 2) {
      if (
        strategyDetails.targetDelta === 0 ||
        strategyDetails.stopLossDelta === 0
      ) {
        setSnackbarMsg("error", "Provide a target and stop loss delta");
        return;
      } else {
            setLoading(true);
            try {
                const response = await axiosInstance.post('trades/strategy/', {
                    name: strategyDetails.strategyName,
                    strategy:
                        strategyDetails.strategySelected === "Butter Fly"
                        ? "FLY"
                        : strategyDetails.strategySelected.toUpperCase(),
                    isop: strategyDetails.isop,
                    target: strategyDetails.targetDelta,
                    sl: strategyDetails.stopLossDelta,
                    user: JSON.parse(localStorage.getItem("loggedInUser")).pk,
                });

                if(response.status === 201) {
                    const strategyId = response.data.id;
                    for(var position of strategyDetails.positions) {
                        await axiosInstance.post('trades/', {
                            order_type: position.position === "buy" ? "B" : "S",
                            option_type: position.optionType === 3 ? "C" : "P",
                            instrument: strategyDetails.instrument,
                            expiry_date: moment(strategyDetails.expiry, "DD MMM YYYY").format(
                                "YYYY-MM-DD"
                            ),
                            strike_price: position.strikePrice,
                            lots: strategyDetails.lots,
                            strategy: strategyId,
                        });
                    }

                    setSnackbarMsg("success", "Strategy created successfully");
                    setTimeout(() => {
                        navigate('/strategies');
                    }, 1500);
                }
            } catch(err) {
                let msg = '';
                if(err.request.status === 404)
                    msg = err.message;
                else if(err.request.status === 401 || err.request.status === 400) {
                    Object.entries(err.response.data).forEach(([key, value]) => {
                        msg += key + ': ' + value + "\n";
                    });
                }

                setSnackbarMsg("error", msg);
            } finally {
                setLoading(false);
            }
        // setLoading(true);
        // var res = await saveStrategy(strategyDetails);
        // if (res.status === 201) {
        //   var strategyId = null;
        //   await res.json().then((data) => {
        //     strategyId = data.id;
        //   });
        //   for (const position of strategyDetails.positions) {
        //     var tradeRes = await createTrades(
        //       position,
        //       strategyDetails,
        //       strategyId
        //     );
        //     if (tradeRes.status === 201) {
        //       await tradeRes.json().then((data) => data);
        //     }
        //   }
        //   setSnackbarMsg("success", "Strategy created successfully");
        //   setTimeout(() => {
        //     navigate("/strategies");
        //   }, 1500);
        // } else if (res.status === 400) {
        //   await res.json().then((data) => setSnackbarMsg("error", data.name));
        // } else {
        //   setSnackbarMsg(
        //     "error",
        //     "There was some error in creating the strategy"
        //   );
        // }
        // setLoading(false);
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setSnackbarMsg = (type, msg) => {
    setSnackBarOpen(true);
    setDisplayMsg({
      type: type,
      msg: msg,
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  return (
    <div className="flex flex-col h-[94vh]">
      <div className="flex justify-center items-center py-10 w-full gradient-bg">
        <h1 className="text-6xl text-white font-semibold">Add Strategy</h1>
      </div>

      <div className="flex flex-auto flex-col pt-7 pb-4 w-full items-start overflow-y-auto">
        <CustomStepper
          steps={["Trade Setup", "Select strategy", "Confirm Strategy"]}
          activeStep={activeStep}
        />
        <Fragment>
          <div className="my-7 flex flex-auto overflow-y-auto w-full">
            {(() => {
              switch (activeStep) {
                case 0:
                  return (
                    <SpecifyStrategyDetails
                      strategyDetails={strategyDetails}
                      setStrategyDetails={setStrategyDetails}
                      optionInstrumentsList={optionInstrumentsList.current}
                    />
                  );
                case 1:
                  return (
                    <AddStrategies
                      strategyDetails={strategyDetails}
                      setStrategyDetails={setStrategyDetails}
                      optionInstrumentsList={optionInstrumentsList.current}
                    />
                  );
                case 2:
                  return (
                    <ConfirmStrategy
                      strategyDetails={strategyDetails}
                      setStrategyDetails={setStrategyDetails}
                    />
                  );
                default:
                  return "";
              }
            })()}
          </div>
          <div className="flex gap-7">
            <ColoredButton
              disabled={activeStep === 0}
              variant="contained"
              size="small"
              startIcon={<NavigateBefore />}
              onClick={handleBack}
            >
              Back
            </ColoredButton>
            <ColoredButton
              variant="contained"
              size="small"
              endIcon={activeStep !== 2 && <NavigateNext />}
              onClick={handleNext}
            >
              {activeStep === 2 ? "Save Strategy" : "Next"}
            </ColoredButton>
          </div>

          <Snackbar
            open={snackBarOpen}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
          >
            <Alert
              variant="filled"
              onClose={handleSnackbarClose}
              severity={displayMsg.type}
              sx={{ width: "100%" }}
            >
              {displayMsg.msg}
            </Alert>
          </Snackbar>

          <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
            <Loading />
          </Backdrop>
        </Fragment>
      </div>
    </div>
  );
}

export default AddStrategy;
