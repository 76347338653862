import * as apiConfig from "../Configuration/ApiConfig";

export default async function getQuote(segment, instrumentId) {
  const response = await fetch(`${apiConfig.BASE_URL}/instruments/quotes/`, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("APIAuthToken"),
    },
    body: JSON.stringify({
      instruments: [
        {
          exchangeSegment: segment,
          exchangeInstrumentID: instrumentId,
        },
      ],
      xtsMessageCode: 1512,
      publishFormat: "JSON",
    }),
  });

  return response;
}
