import {
  ArrowDownward,
  ArrowUpward,
  Circle,
  Delete,
  Edit,
} from "@mui/icons-material";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid";
import * as React from "react";
import NoRowsOverlay from "./NoRowsOverlay";
import ColoredButton from "./ColoredButton";
import StyledDataGrid from "./StyledDataGrid";
import { useState } from "react";
import BrokerSelectDialog from "./BrokerSelectDialog";
import { Alert, Backdrop, Chip, Link, Snackbar } from "@mui/material";
import Loading from "./Loading";
import TradeLegsDetails from "./TradeLegsDetails";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "./ConfirmDialog";
import moment from "moment";
import EditStrategyDialog from "./EditStrategyDialog";
import { useEffect } from "react";
import * as apiConfig from "../Configuration/ApiConfig";
import * as io from "socket.io-client";
import getOptionSymbol from "../HelperMethods/getOptionSymbol";
import subscribeInstruments from "../HelperMethods/subscribeInstruments";
import axiosInstance from "../Configuration/axiosConfig";

export default function StrategiesTable({ savedStrategies }) {
  const columns = [
    {
      field: "name",
      headerName: "Strategy Name",
      headerAlign: "center",
      align: "center",
      renderCell: renderStrategyName,
      width: 200,
    },
    {
      field: "instrument",
      headerName: "Instrument",
      width: 150,
    },

    {
      field: "strategy",
      headerName: "Strategy Type",
      renderCell: renderStrategyType,
      width: 200,
    },
    {
      field: "lots",
      headerName: "Lots",
      width: 100,
    },
    {
      field: "isop",
      type: "number",
      headerName: "ISOP",
      width: 135,
      valueGetter: (params) => {
        return params.row.status === "executed"
          ? params.row.csop
          : params.row.isop;
      },
      align: "left",
      headerAlign: "left",
    },
    {
      field: "target",
      type: "number",
      headerName: "Target Delta",
      width: 135,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "sl",
      type: "number",
      headerName: "StopLoss Delta",
      width: 135,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: renderStatus,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      type: "actions",
      width: 135,
      getActions: (params) =>
        params.row.status === "saved" || params.row.status === "executed"
          ? [
              <GridActionsCellItem
                icon={<Edit sx={{ color: "black" }} />}
                label="Edit"
                onClick={handleEditClick(params.id)}
              />,

              <GridActionsCellItem
                icon={<Delete sx={{ color: "red" }} />}
                label="Delete"
                onClick={handleDeleteClick(params.id)}
              />,
            ]
          : [],
    },
  ];

  const handleEditClick = (id) => () => {
    setEditStrategyDialogOpen(true);
  };

  const handleDeleteClick = (id) => () => {
    new Promise((resolve, reject) => {
      setPromiseArguments({
        promiseArgs: { resolve, reject, id },
        msg: "Are you sure you want to delete this strategy?",
      });
    });
  };

  function renderStrategyType(props) {
    const { value } = props;

    return (
      <div>
        <Chip label={value} color="secondary" variant="outlined" />
      </div>
    );
  }

  function renderStrategyName(props) {
    const { value, id } = props;

    return (
      <div>
        <Link
          sx={{
            color: "black",
            fontFamily: "Poppins",
            fontWeight: "bold",
            textDecoration: "none",
            borderBottom: "1px solid black",
          }}
          component="button"
          variant="body2"
          onClick={() => showStrategyLegDetails(id)}
        >
          {value}
        </Link>
      </div>
    );
  }

  function renderStatus(props) {
    const { value } = props;

    return (
      <div>
        {value === "placed" ? (
          <h1 className="text-green-600 font-bold">In execution</h1>
        ) : (
          <Circle sx={{ color: "red" }} fontSize="small" />
        )}
      </div>
    );
  }

  const [rows] = useState(savedStrategies);

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [tradeLegsOpen, setTradeLegsOpen] = useState(false);

  const [tradeLegs, setTradeLegs] = useState({
    strategyName: "",
    legs: [],
  });

  const [loading, setLoading] = useState(false);

  const [displayMsg, setDisplayMsg] = useState({
    type: "success",
    msg: "",
  });

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [promiseArguments, setPromiseArguments] = useState({
    promiseArgs: null,
    msg: "",
  });

  const [editStrategyDialogOpen, setEditStrategyDialogOpen] = useState(false);

  const navigate = useNavigate();

  const apiRef = useGridApiRef();

  const onExecuteBtnClick = () => {
    setDialogOpen(true);
  };

  const onConfirmBtnClick = async () => {
    setLoading(true);
    var selectedRows = apiRef.current.getSelectedRows();
    var promiseArray = [];
    for(var row of selectedRows)
        promiseArray.push(axiosInstance.post(`trades/strategy/execute?strategy_id=${row[1].id}`));

    Promise.all(promiseArray).then(() => {
        setSnackBarOpen(true);
        setDisplayMsg({ msg: "Strategy is in execution", type: "success" });
        setTimeout(() => {
            navigate(0);
        }, 1000);
    }).catch((err) => {
        let msg = '';
        if(err.request.status === 404)
            msg = err.message;
        else if(err.request.status === 401 || err.request.status === 400)
            msg = msg = err.response.data;
        else
            msg = err.message;
        setSnackBarOpen(true);
        setDisplayMsg({msg: msg, type: "error"});
    })

    setLoading(false);
    setDialogOpen(false);
  };

  const showStrategyLegDetails = async (id) => {
    var strategy = savedStrategies.find((strategy) => strategy.id === id);
    setTradeLegs({ name: strategy.name, legs: strategy.trades });
    setTradeLegsOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  const handleDeleteConfirmNo = () => {
    const { resolve, id } = promiseArguments.promiseArgs;
    resolve(id);
    setPromiseArguments({ promiseArgs: null, msg: "" });
  };

  const handleDeleteConfirmYes = async () => {
    const { resolve, id } = promiseArguments.promiseArgs;
    setLoading(true);
    try {
        const response = await axiosInstance.delete(`/trades/strategy/?id=${id}`);

        if(response.status === 200) {
            setSnackBarOpen(true);
            setDisplayMsg({msg: "Strategy deleted successfully", type: "success"});
            navigate(0);
        }

    } catch(err) {
        setSnackBarOpen(true);
        setDisplayMsg({msg: err.message, type: "error"});
    } finally {
        setLoading(false);
        resolve(id);
        setPromiseArguments({ promiseArgs: null, msg: "" });
    }
  };

  const subscibeToInstruments = async (instrumentIds) => {
    var instrumentsToSubscribe = instrumentIds.map((instrument) => {
      return { exchangeSegment: 2, exchangeInstrumentID: instrument };
    });

    var subscription = await subscribeInstruments(instrumentsToSubscribe);
    // if(subscription.type === "success")
    // calculateISOP(subscription.result.listQuotes)
  };

  const websocketConn = () => {
    const socket = io("https://ttblaze.iifl.com", {
      path: "/marketdata/socket.io/",
      autoConnect: false,
      reconnection: false,
      withCredentials: true,
      query: {
        token: localStorage.getItem("APIAuthToken"),
        userId: "PRMA0101_162acff6999b23969a7665",
        publishFormat: "JSON",
        broadcastMode: "Full",
      },
      transports: ["websocket"],
    });

    socket.connect();
    socket.on("connect", () => {
      console.log(socket.id);
    });
    // socket.on("1502-json-full", function (data) {
    //   console.log("data is " + data);
    // });
  };

  useEffect(() => {
    async function getOptionSymbols() {
      // var instrumentIds = [];
      // var currentStrategies = savedStrategies.filter(
      //   (strategy) =>
      //     !moment(strategy.trades[0].expiry_date).isBefore(
      //       moment().format("YYYY-MM-DD")
      //     )
      // );
      // for (var strategy of currentStrategies) {
      //   for (var trade of strategy.trades) {
      //     var optionSymbol = await getOptionSymbol(
      //       trade.instrument,
      //       moment(trade.expiry_date, "YYYY-MM-DD").format("DDMMMYYYY"),
      //       trade.option_type === "C" ? "CE" : "PE",
      //       trade.strike_price
      //     );
      //     if (optionSymbol?.type === "error") {
      //       console.log(optionSymbol.description);
      //     } else
      //       instrumentIds.push(optionSymbol.result[0].ExchangeInstrumentID);
      //   }
      // }
      // subscibeToInstruments(instrumentIds);
      //   websocketConn();
    }
    getOptionSymbols();
  }, []);

  if (loading) {
    return (
      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
        <Loading />
      </Backdrop>
    );
  }

  return (
    <div className="h-[400px] width-full flex flex-col gap-5">
      <StyledDataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 5, page: 0 } },
        }}
        pageSizeOptions={[5, 10, 15]}
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay msg="You have not created a strategy yet" />
          ),
          columnSortedAscendingIcon: () => (
            <ArrowDownward fontSize="small" sx={{ color: "black" }} />
          ),
          columnSortedDescendingIcon: () => (
            <ArrowUpward fontSize="small" sx={{ color: "black" }} />
          ),
        }}
        disableRowSelectionOnClick
        checkboxSelection
        disableColumnMenu
        getRowClassName={(params) =>
          moment(params.row.trades[0].expiry_date).isBefore(
            moment().format("YYYY-MM-DD")
          ) || params.row.status === "placed"
            ? "strategy-pastExpiry"
            : ""
        }
        isRowSelectable={(params) =>
          !moment(params.row.trades[0].expiry_date).isBefore(
            moment().format("YYYY-MM-DD")
          ) && params.row.status !== "placed"
        }
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        apiRef={apiRef}
      />

      <div className="flex justify-between items-end">
        <ColoredButton
          disabled={!rowSelectionModel.length > 0}
          variant="contained"
          size="small"
          onClick={onExecuteBtnClick}
        >
          Execute Strateg
          {rowSelectionModel.length > 1 ? "ies" : "y"}
        </ColoredButton>

        <h1 className="text-xs italic">
          *The strategies greyed out either have a past expiry date or is in
          execution. They cannot be executed.
        </h1>
      </div>

      {dialogOpen && (
        <BrokerSelectDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          handleConfirmClick={onConfirmBtnClick}
        />
      )}

      {setEditStrategyDialogOpen && (
        <EditStrategyDialog
          dialogOpen={editStrategyDialogOpen}
          setDialogOpen={setEditStrategyDialogOpen}
        />
      )}

      {promiseArguments.promiseArgs && (
        <ConfirmDialog
          promiseArgs={promiseArguments.promiseArgs}
          msg={promiseArguments.msg}
          handleNo={handleDeleteConfirmNo}
          handleYes={handleDeleteConfirmYes}
        />
      )}

      {tradeLegsOpen && (
        <TradeLegsDetails
          open={tradeLegsOpen}
          setOpen={setTradeLegsOpen}
          tradeLegs={tradeLegs}
        />
      )}

      {snackBarOpen && (
        <Snackbar
          open={snackBarOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
        >
          <Alert
            variant="filled"
            onClose={handleSnackbarClose}
            severity={displayMsg.type}
            sx={{ width: "100%" }}
          >
            {displayMsg.msg}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
