import styled from "@emotion/styled";
import { Button } from "@mui/material";

const OutlinedButton = styled(Button)({
  borderColor: "#643FBD",
  borderWidth: 2,
  color: "#643FBD",
  "&:hover": {
    borderColor: "#643FBD",
    borderWidth: 2,
    backgroundColor: "#DED8F0",
  },
});

export default OutlinedButton;
