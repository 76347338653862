import styled from "@emotion/styled";
import { TextField } from "@mui/material";

const TextInput = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    fontFamily: "Poppins",
    "&.Mui-focused fieldset": { borderColor: "#643FBD" },
    "&.Mui-error fieldset": { border: "2px solid red" },
  },
});

export default TextInput;
