import styled from "@emotion/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import MetricCard from "./MetricCard";
import {
  AccessTime,
  AttachMoney,
  CalendarMonth,
  ExpandMore,
} from "@mui/icons-material";
import { LineGraph } from "./LineGraph";

const Item = styled(Paper)(() => ({
  backgroundColor: "#fff",
  textAlign: "center",
  color: "black",
  borderRadius: "20px",
  margin: "0px 14px",
}));

function DashboardMasonry() {
  return (
    <div className="py-8">
      <Grid2 container rowSpacing={5} className="overflow-y-auto">
        <Grid2 xs={4}>
          <Item elevation={6}>
            <MetricCard
              icon={<AccessTime sx={{ color: "black" }} fontSize="large" />}
              title="Today's P&L"
              content="+ Rs. 2000"
            />
          </Item>
        </Grid2>
        <Grid2 xs={4}>
          <Item elevation={6}>
            <MetricCard
              icon={<CalendarMonth sx={{ color: "black" }} fontSize="large" />}
              title="Monthly P&L"
              content="+ Rs. 20000"
            />
          </Item>
        </Grid2>
        <Grid2 xs={4}>
          <Item elevation={6}>
            <MetricCard
              icon={<AttachMoney sx={{ color: "black" }} fontSize="large" />}
              title="Total P&L"
              content="+ Rs. 80000"
            />
          </Item>
        </Grid2>

        <Grid2 xs={4}>
          <Item elevation={6}>
            <MetricCard
              icon={null}
              title="Highest profiting strategy"
              content="Strategy 2"
            />
          </Item>
        </Grid2>

        <Grid2 xs={8}>
          <Item elevation={6}>
            <LineGraph />
          </Item>
        </Grid2>

        <Grid2 xs={4}>
          <Item elevation={6}>
            <MetricCard
              icon={null}
              title="Lowest profiting strategy"
              content="Strategy 7"
            />
          </Item>
        </Grid2>
        <Grid2 xs={8}>
          <Item elevation={6}>
            <Accordion className="p-8">
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h1>Accordion 1</h1>
              </AccordionSummary>
              <AccordionDetails>
                <h1>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </h1>
              </AccordionDetails>
            </Accordion>
          </Item>
        </Grid2>

        {/* <Grid2 container xs={4}>
          <Grid2 xs={12}>
            <Item elevation={6}>
              <MetricCard
                icon={null}
                title="Highest profiting strategy"
                content="Strategy 2"
              />
            </Item>
          </Grid2>
          <Grid2 xs={12}>
            <Item elevation={6}>
              <MetricCard
                icon={null}
                title="Lowest profiting strategy"
                content="Strategy 7"
              />
            </Item>
          </Grid2>
        </Grid2>

        <Grid2 container xs={8}>
          <Grid2 xs={12}>
            <Item elevation={6}>
              <LineGraph />
            </Item>
          </Grid2>
          <Grid2 xs={12}>
            <Item elevation={6}>
              <Accordion className="p-8">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h1>Accordion 1</h1>
                </AccordionSummary>
                <AccordionDetails>
                  <h1>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </h1>
                </AccordionDetails>
              </Accordion>
            </Item>
          </Grid2>
        </Grid2> */}
      </Grid2>
    </div>
  );
}

export default DashboardMasonry;
