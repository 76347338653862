import { Key } from "@mui/icons-material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import OutlinedButton from "../Components/OutlinedButton";
import DashboardMasonry from "../Components/DashboardMasonry";
import BrokerLoginDialog from "../Components/BrokerLoginDialog";
import { useConfigData } from "../Context/ConfigDataProvider";
import WatchList from "../Components/WatchList";
import axiosInstance from "../Configuration/axiosConfig";

function DashboardPage() {

  const [open, setOpen] = useState(false);

  const [brokersSetup, setBrokersSetup] = useState([]);

  const brokersLoggedInCount = useRef();

  const showBrokerLogin = () => {
    setOpen(true);
  };

  const { configData } = useConfigData();
  
  useEffect(() => {
    async function getBrokersSetup() {
        try {
            const response = await axiosInstance.post('auth/user/brokers/');
            if(response.status === 200)
                setBrokersSetup(response.data);
        } catch(err) {
            // Implement new err mechanism in this page
            console.log(err);
        }
    }
    getBrokersSetup();
  }, []);

  const getBrokersLoggedInCount = useMemo(() => {
    let count = 0;
    Object.values(brokersSetup).forEach((value) => {
      if (value.logged_in) {
        count++;
      }
    });

    brokersLoggedInCount.current = count;
  }, [brokersSetup]);

  console.log(configData);

  return (
    <div className="flex gap-5 py-2 h-[94vh]">
      <div className="flex flex-col">
          <div className="flex justify-between">
            <h1 className="font-medium text-4xl">Good morning, {configData.userDetails?.first_name === "" ? configData.userDetails?.username : configData.userDetails?.first_name}</h1>
            <OutlinedButton
              variant="outlined"
              startIcon={<Key />}
              onClick={showBrokerLogin}
            >
              Broker Login ({brokersLoggedInCount.current}/
              {Object.keys(brokersSetup).length})
            </OutlinedButton>
            <BrokerLoginDialog
              open={open}
              setOpen={setOpen}
              brokersSetup={brokersSetup}
              setBrokersSetup={setBrokersSetup}
            />
          </div>
          <DashboardMasonry />
      </div>
      <WatchList userDetails = {configData.userDetails} />
    </div>
  );
}

export default DashboardPage;
