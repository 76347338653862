import * as apiConfig from "../Configuration/ApiConfig";

export default async function getInstruments(segments) {
  const response = await fetch(`${apiConfig.BASE_URL}/instruments/master`, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("APIAuthToken"),
    },
    body: JSON.stringify({
      exchangeSegmentList: segments,
    }),
  });

  return await response.json();
}
