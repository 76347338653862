import styled from "@emotion/styled";
import { Edit } from "@mui/icons-material";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import React from "react";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#643FBD",
    color: "white",
    fontFamily: "Poppins",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "black",
    fontFamily: "Poppins",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
  },
}));

function StrategyPositions({ strategyDetails, toggleDialog }) {
  const handleEditClick = () => {
    toggleDialog(true);
  };

  return (
    <TableContainer component={Paper} className="mt-5">
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Position</StyledTableCell>
            <StyledTableCell align="center">Strike Price</StyledTableCell>
            <StyledTableCell align="center">Expiry</StyledTableCell>
            <StyledTableCell align="center">Entry Price</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {strategyDetails.positions.map((position, idx) => {
            return (
              <TableRow key={idx}>
                <StyledTableCell align="center">
                  {position.position === "buy" ? "+" : "-"}
                  {strategyDetails.lots}x
                </StyledTableCell>
                <StyledTableCell align="center">
                  {position.strikePrice}{" "}
                  {position.optionType === 3 ? "CE" : "PE"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {strategyDetails.expiry}
                </StyledTableCell>
                <StyledTableCell align="center">
                  Rs. {position.entryPrice}
                </StyledTableCell>
                <StyledTableCell>
                  <IconButton size="small" onClick={handleEditClick}>
                    <Edit sx={{ color: "black" }} />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default StrategyPositions;
