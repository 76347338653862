import React, { useEffect, useState } from "react";
import ProfileDropdown from "./ProfileDropdown";
import ZerodhaLogo from "../assets/zerodha_logo.png";
import AngelOneLogo from "../assets/angelone_logo.png";
import FinvasiaLogo from "../assets/finvasia_logo.png";
import { IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import axiosInstance from "../Configuration/axiosConfig";

function WatchList({ userDetails }) {
  const [brokersSetup, setBrokersSetup] = useState([]);

  useEffect(() => {
    async function getBrokersSetup() {
        try {
            const response = await axiosInstance.post('auth/user/brokers/');
            if(response.status === 200)
                setBrokersSetup(Object.keys(response.data));
        } catch(err) {
            // Implement new err mechanism in this page
            console.log(err);
        }
    }
    getBrokersSetup();
  }, []);

  return (
    <div className="bg-primary/30 h-full flex flex-col p-3 rounded-3xl w-3/4">
      <ProfileDropdown userDetails={userDetails} />

      <div className="flex justify-between items-center mt-5">
        <h1 className="font-semibold">
          Brokers Added ({brokersSetup.length}/3)
        </h1>
        <div className="flex">
          {brokersSetup.includes("zerodha") && (
            <img className="h-7 w-7" src={ZerodhaLogo} alt="Zerodha" />
          )}
          {brokersSetup.includes("angelone") && (
            <img className="h-7 w-7" src={AngelOneLogo} alt="Angel One" />
          )}
          {brokersSetup.includes("finvasia") && (
            <img className="h-7 w-7" src={FinvasiaLogo} alt="Finvasia" />
          )}
        </div>
      </div>

      <div className="flex justify-between items-center mt-5">
        <h1 className="font-semibold">Your watchlist</h1>
        <IconButton size="small">
          <Add sx={{ color: "black" }} />
        </IconButton>
      </div>
    </div>
  );
}

export default WatchList;
