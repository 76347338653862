import {
  AttachMoneyOutlined,
  BugReportOutlined,
  ConstructionOutlined,
  HomeOutlined,
  NavigateBefore,
  NavigateNext,
  ReceiptOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { Collapse, IconButton } from "@mui/material";
import React, { useState } from "react";
import logo from "../assets/tradebuddys_logo.png";
import NavItem from "./NavItem";
import "../Styles/CustomStyles.css";
import { NavLink } from "react-router-dom";

function NavigationDrawer() {
  const [toggleNav, setToggleNav] = useState(false);
  const toggleNavDrawer = () => {
    setToggleNav((prev) => !prev);
  };

  return (
    <div className="w-max bg-primary h-full rounded-3xl overflow-hidden">
      <Collapse
        orientation="horizontal"
        in={toggleNav}
        collapsedSize={65}
        className="collapse-full-height"
      >
        <div className="flex flex-col h-full">
          <div className="flex items-center mt-5 ml-4 w-max">
            <img src={logo} alt="Temporary logo" className="w-9" />
            <h1 className="text-xl font-bold ml-3.5 pr-6 text-white">
              Trade Buddys
            </h1>
          </div>
          <div className="mt-4 pl-4">
            <IconButton onClick={toggleNavDrawer}>
              {toggleNav ? (
                <NavigateNext
                  sx={{ "&.MuiSvgIcon-root": { color: "white" } }}
                />
              ) : (
                <NavigateBefore
                  sx={{ "&.MuiSvgIcon-root": { color: "white" } }}
                />
              )}
            </IconButton>
          </div>
          <div className="mt-3 pr-6 w-max">
            <NavLink to="/home">
              {({ isActive }) => {
                return (
                  <NavItem
                    collapsed={toggleNav}
                    menuItem="Home"
                    iconComponent={
                      <HomeOutlined
                        sx={{
                          "&.MuiSvgIcon-root": { color: "white" },
                          fontSize: 30,
                        }}
                      />
                    }
                    active={isActive}
                  />
                );
              }}
            </NavLink>
            <NavLink to="/strategies">
              {({ isActive }) => {
                return (
                  <NavItem
                    collapsed={toggleNav}
                    menuItem="Strategy Builder"
                    iconComponent={
                      <ConstructionOutlined
                        sx={{
                          "&.MuiSvgIcon-root": { color: "white" },
                          fontSize: 30,
                        }}
                      />
                    }
                    active={isActive}
                  />
                );
              }}
            </NavLink>
            <NavLink to="/home/orders">
              {({ isActive }) => {
                return (
                  <NavItem
                    collapsed={toggleNav}
                    menuItem="Your orders"
                    iconComponent={
                      <AttachMoneyOutlined
                        sx={{
                          "&.MuiSvgIcon-root": { color: "white" },
                          fontSize: 30,
                        }}
                      />
                    }
                    active={isActive}
                  />
                );
              }}
            </NavLink>
            <NavLink to="/home/orders">
              {({ isActive }) => {
                return (
                  <NavItem
                    collapsed={toggleNav}
                    menuItem="Reports"
                    iconComponent={
                      <ReceiptOutlined
                        sx={{
                          "&.MuiSvgIcon-root": { color: "white" },
                          fontSize: 30,
                        }}
                      />
                    }
                    active={isActive}
                  />
                );
              }}
            </NavLink>
            <NavLink to="/home/orders">
              {({ isActive }) => {
                return (
                  <NavItem
                    collapsed={toggleNav}
                    menuItem="Tester"
                    iconComponent={
                      <BugReportOutlined
                        sx={{
                          "&.MuiSvgIcon-root": { color: "white" },
                          fontSize: 30,
                        }}
                      />
                    }
                    active={isActive}
                  />
                );
              }}
            </NavLink>
          </div>
          <div className="pr-6 flex flex-auto items-end">
            <NavLink to="/settings">
              {({ isActive }) => {
                return (
                  <NavItem
                    collapsed={toggleNav}
                    menuItem="Settings"
                    iconComponent={
                      <SettingsOutlined
                        sx={{
                          "&.MuiSvgIcon-root": { color: "white" },
                          fontSize: 30,
                        }}
                      />
                    }
                    active={isActive}
                  />
                );
              }}
            </NavLink>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default NavigationDrawer;
