import styled from "@emotion/styled";
import { Button } from "@mui/material";

const ColoredButton = styled(Button)({
  padding: "0.5em 1em",
  backgroundColor: "#643FBD",
  "&:hover": {
    backgroundColor: "#4918BD",
  },
});

export default ColoredButton;
