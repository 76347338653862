import React, { useEffect, useState } from "react";
import profileImg from "../assets/UserProfileImgTemp.png";
import TextInput from "../Components/TextInput";
import { Alert, Backdrop, Link, Snackbar } from "@mui/material";
import { CancelOutlined, CheckCircleOutlineRounded } from "@mui/icons-material";
import ColoredButton from "../Components/ColoredButton";
import Loading from "../Components/Loading";
import axiosInstance from "../Configuration/axiosConfig";

function SettingsPage() {
  const [userDetails, setUserDetails] = useState({});

  const [basicDetailsEditMode, setBasicDetailsEditMode] = useState(false);

  const [phoneNumberEditMode, setPhoneNumberEditMode] = useState(false);

  const [isError, setIsError] = useState({
    username: false,
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
  });

  const [helperText, setHelperText] = useState({
    usernameHelperText: "",
    firstNameHelperText: "",
    lastNameHelperText: "",
    emailHelperText: "",
    phoneNumberHelperText: "",
  });

  const [displayMsg, setDisplayMsg] = useState({
    type: "success",
    msg: "",
  });

  const [loading, setLoading] = useState(false);

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserDetails((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const toggleBasicDetailsEditMode = async () => {
    if (basicDetailsEditMode) {
      setLoading(true);
      try {
        const response = await axiosInstance.put('auth/user/', userDetails);

        if(response.status === 200) {
            localStorage.setItem("userDetails", JSON.stringify(response.data));
            setSnackBarOpen(true);
            setDisplayMsg({msg: "User details updated successfully", type: "success"});
        }
      } catch(err) {
        setSnackBarOpen(true);
        setDisplayMsg({msg: err.message, type: "error"});
      } finally {
        setLoading(false);
      }
    }
    setBasicDetailsEditMode((prev) => !prev);
  };

  const validationController = (event) => {
    const { name, value } = event.target;

    setIsError((prevData) => {
      return { ...prevData, [name]: validateUserInput(name, value) };
    });
  };

  const validateUserInput = (name, value) => {
    // if (name === "email") {
    //   if (value === "") {
    //     setHelperText((prevData) => {
    //       return { ...prevData, emailHelperText: "Email is required" };
    //     });
    //     return true;
    //   } else if (!value.match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/)) {
    //     setHelperText((prevData) => {
    //       return { ...prevData, emailHelperText: "Invalid email format" };
    //     });
    //     return true;
    //   }
    //   return false;
    // }

    if (name === "username") {
      if (value === "") {
        setHelperText((prevData) => {
          return { ...prevData, usernameHelperText: "Username is required" };
        });
        return true;
      } else if (
        !value.match(/^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/)
      ) {
        setHelperText((prevData) => {
          return { ...prevData, usernameHelperText: "Invalid username" };
        });
        return true;
      }
      return false;
    }

    if (name === "phone") {
      if (value === "") {
        setHelperText((prevData) => {
          return {
            ...prevData,
            phoneNumberHelperText: "Phone number is required",
          };
        });
        return true;
      } else if (!value.match(/^\+?[1-9][0-9]{7,14}$/)) {
        setHelperText((prevData) => {
          return { ...prevData, phoneNumberHelperText: "Invalid phone number" };
        });
        return true;
      }
      return false;
    }
    return false;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  useEffect(() => {
    var _userDetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(_userDetails);
  }, []);

  return (
    <div className="flex flex-col overflow-y-auto h-[94vh]">
      <div className="flex justify-center items-center py-10 w-full gradient-bg">
        <h1 className="text-6xl text-white font-semibold">Settings</h1>
      </div>

      <div className="flex flex-col gap-5 my-5 overflow-y-auto">
        <div className="flex gap-5 items-center">
          <img src={profileImg} alt="temp logo" />
          <div>
            <h1 className="font-bold text-3xl">{userDetails.username}</h1>
            <h1>{userDetails.email}</h1>
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <h1 className="font-semibold text-xl">Basic Details</h1>
          <div className="flex gap-12">
            <div className="flex flex-col gap-3 w-3/5">
              <div className="flex gap-9">
                <div className="flex flex-col w-2/4">
                  <label
                    htmlFor="username"
                    className="text-lg font-medium mb-2"
                  >
                    Username:
                  </label>
                  <TextInput
                    name="username"
                    disabled={!basicDetailsEditMode}
                    value={userDetails.username}
                    error={isError.username}
                    helperText={
                      isError.username ? helperText.usernameHelperText : ""
                    }
                    placeholder={basicDetailsEditMode ? "Enter Username" : ""}
                    size="small"
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={validationController}
                  />
                </div>
                <div className="flex flex-col w-2/4">
                  <label
                    htmlFor="first_name"
                    className="text-lg font-medium mb-2"
                  >
                    First Name:
                  </label>
                  <TextInput
                    name="first_name"
                    disabled={!basicDetailsEditMode}
                    value={userDetails.first_name}
                    error={isError.firstName}
                    helperText={
                      isError.firstName ? helperText.firstNameHelperText : ""
                    }
                    placeholder={basicDetailsEditMode ? "Enter First Name" : ""}
                    size="small"
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={validationController}
                  />
                </div>
              </div>
              <div className="flex gap-9">
                <div className="flex flex-col w-2/4">
                  <label
                    htmlFor="last_name"
                    className="text-lg font-medium mb-2"
                  >
                    Last Name:
                  </label>
                  <TextInput
                    name="last_name"
                    disabled={!basicDetailsEditMode}
                    value={userDetails.last_name}
                    error={isError.lastName}
                    helperText={
                      isError.lastName ? helperText.lastNameHelperText : ""
                    }
                    placeholder={basicDetailsEditMode ? "Enter Last Name" : ""}
                    size="small"
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={validationController}
                  />
                </div>
                <div className="flex flex-col w-2/4">
                  <label htmlFor="email" className="text-lg font-medium mb-2">
                    Email:
                  </label>
                  <TextInput
                    name="email"
                    disabled={!basicDetailsEditMode}
                    value={userDetails.email}
                    error={isError.email}
                    helperText={isError.email ? helperText.emailHelperText : ""}
                    placeholder={basicDetailsEditMode ? "Enter Email" : ""}
                    size="small"
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={validationController}
                  />
                </div>
              </div>

              <ColoredButton
                variant="contained"
                className="w-fit"
                size="small"
                onClick={toggleBasicDetailsEditMode}
              >
                {basicDetailsEditMode ? "Save Details" : "Edit Basic Details"}
              </ColoredButton>
            </div>

            <div className="flex flex-col gap-3">
              <h1 className="font-semibold text-xl">Account Verification</h1>
              <div className="flex items-center gap-5">
                <h1 className="font-medium">Email Address</h1>
                {userDetails.email_verifed ? (
                  <CheckCircleOutlineRounded color="success" fontSize="small" />
                ) : (
                  <CancelOutlined color="error" fontSize="small" />
                )}
                {!userDetails.email_verifed && (
                  <Link
                    sx={{
                      color: "#643FBD",
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      textDecoration: "none",
                      borderBottom: "1px solid black",
                    }}
                    component="button"
                    variant="body2"
                  >
                    Click to verify
                  </Link>
                )}
              </div>
              <div className="flex items-center gap-5">
                <h1 className="font-medium">Phone number</h1>
                {userDetails.phone_verified ? (
                  <CheckCircleOutlineRounded color="success" fontSize="small" />
                ) : (
                  <CancelOutlined color="error" fontSize="small" />
                )}
                {!userDetails.phone_verified && (
                  <Link
                    sx={{
                      color: "#643FBD",
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      textDecoration: "none",
                      borderBottom: "1px solid black",
                    }}
                    component="button"
                    variant="body2"
                  >
                    Click to verify
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3 w-fit">
          <h1 className="font-semibold text-xl">Password</h1>
          <Link
            sx={{
              color: "#643FBD",
              fontFamily: "Poppins",
              fontWeight: "bold",
              textDecoration: "none",
              borderBottom: "1px solid black",
            }}
            component="button"
            variant="body2"
          >
            Change Password
          </Link>
        </div>

        <div className="flex flex-col gap-3 w-3/5">
          <h1 className="font-semibold text-xl">Phone number</h1>

          <div className="flex flex-col w-2/4">
            <TextInput
              name="phone"
              disabled={!phoneNumberEditMode}
              value={userDetails.phone}
              error={isError.phoneNumber}
              helperText={
                isError.phoneNumber ? helperText.phoneNumberHelperText : ""
              }
              placeholder={phoneNumberEditMode ? "Enter Phone Number" : ""}
              size="small"
              fullWidth
              variant="outlined"
              onChange={handleChange}
              onBlur={validationController}
            />
          </div>

          <Link
            sx={{
              color: "#643FBD",
              fontFamily: "Poppins",
              fontWeight: "bold",
              textDecoration: "none",
              borderBottom: "1px solid black",
              width: "fit-content",
            }}
            component="button"
            variant="body2"
          >
            Change Phone number
          </Link>
        </div>
      </div>
      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
        <Loading />
      </Backdrop>
      <Snackbar
        open={snackBarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert
          variant="filled"
          onClose={handleSnackbarClose}
          severity={displayMsg.type}
          sx={{ width: "100%" }}
        >
          {displayMsg.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SettingsPage;
