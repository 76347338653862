import React from "react";
import PageNotFound from "./Pages/PageNotFound";
import { Route, Routes } from "react-router-dom";
import Index from "./Pages/Index";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import DashboardPage from "./Pages/DashboardPage";
import AddFinvasiaBroker from "./Pages/AddFinvasiaBroker";
import RequireAuth from './Components/RequireAuth';
import DefaultLayout from "./Pages/DefaultLayout";
import AddZerodhaBroker from "./Pages/AddZerodhaBroker";
import AddAngelBroker from "./Pages/AddAngelBroker";
import AddBroker from "./Pages/AddBroker";
import Strategies from "./Pages/Strategies";
import AddStrategy from "./Pages/AddStrategy";
import SettingsPage from "./Pages/SettingsPage";
import { ConfigDataProvider } from "./Context/ConfigDataProvider";
import ActiveSession from "./Components/ActiveSession";

function RoutesSetup() {
  return (
    <Routes>
        <Route path="/" element={<Index />} />

        {/* Login routes */}
        <Route path="login" element={<Login />} />

        {/* Signup routes */}
        <Route path="signup" element={<SignUp />} />


        <Route path="/" element={<DefaultLayout />}>
            <Route element={<RequireAuth />}>
                <Route element={<ActiveSession />}>
                    {/* Dashboard routes */}
                    <Route path="home" element={<ConfigDataProvider><DashboardPage /></ConfigDataProvider>} />
                    <Route path="addBroker">
                        <Route index element={<AddBroker />} />
                        <Route path="finvasia" element={<AddFinvasiaBroker />} />
                        <Route path="zerodha" element={<AddZerodhaBroker />} />
                        <Route path="angelone" element={<AddAngelBroker />} />
                    </Route>

                    {/* Strategy Routes */}
                    <Route path="strategies">
                        <Route index element={<Strategies />} />
                        <Route path="addStrategy" element={<ConfigDataProvider><AddStrategy /></ConfigDataProvider>} />
                    </Route>

                    {/* Settings routes */}
                    <Route path="settings" element={<SettingsPage />} />
                </Route>
            </Route>
        </Route>

        <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default RoutesSetup;
