import { ArrowDropDown, ArrowForward } from "@mui/icons-material";
import { Avatar, Backdrop, ClickAwayListener, IconButton } from "@mui/material";
import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { NavLink, useNavigate } from "react-router-dom";
import UserProfileImgTemp from "../assets/UserProfileImgTemp.png";
import ColoredButton from "./ColoredButton";
import { useRef } from "react";
import Loading from "./Loading";
import axiosInstance from "../Configuration/axiosConfig";

function ProfileDropdown({ userDetails }) {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const nodeRef = useRef(null);
  const navigate = useNavigate();

  const handleDropdownClick = () => {
    setToggleDropdown((prev) => !prev);
  };

  const onLogoutBtnClick = async () => {
    setLoading(true);
    try {
        const response = await axiosInstance.post('auth/logout/', {refresh: JSON.parse(localStorage.getItem("userAuthToken"))?.userToken?.refreshToken});

        if(response.status === 200) {
            localStorage.clear();
            navigate("/login");
        }

    } catch(err) {
        // implement new error mechanism here
        console.log(err.message);
    } finally {
        setLoading(false);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center self-end">
        <h1 className="font-bold text-xl">
          {userDetails.first_name === ""
            ? userDetails.username
            : userDetails.first_name}
        </h1>
        <IconButton onClick={handleDropdownClick}>
          <ArrowDropDown fontSize="small" />
        </IconButton>
      </div>
      <CSSTransition
        nodeRef={nodeRef}
        in={toggleDropdown}
        timeout={300}
        classNames="dropdown"
        unmountOnExit
      >
        <ClickAwayListener onClickAway={() => setToggleDropdown(false)}>
          <div ref={nodeRef} className="relative">
            <div className="px-9 py-5 bg-white w-max absolute right-0 overflow-hidden drop-shadow-lg rounded-md flex flex-col divide-y divide-black">
              <div className="flex">
                <Avatar
                  alt="Profile"
                  src={UserProfileImgTemp}
                  sx={{ width: 128, height: 128 }}
                />
                <div className="flex gap-1 ml-5 flex-col justify-center w-min">
                  <h1 className="font-bold text-base">
                    {userDetails.username}
                  </h1>
                  <h1 className="font-bold text-base">
                    Phone number:{" "}
                    <span className="font-normal text-sm">
                      {userDetails.phone}
                    </span>
                  </h1>
                  <h1 className="font-bold text-base">
                    Email:{" "}
                    <span className="font-normal text-sm">
                      {userDetails.email}
                    </span>
                  </h1>
                </div>
              </div>

              <NavLink
                to="/settings"
                className="flex justify-between items-center mt-5 py-1"
                onClick={() => setToggleDropdown(false)}
              >
                <h1 className="text-primary">Settings</h1>
                <IconButton>
                  <ArrowForward
                    fontSize="small"
                    sx={{ "&.MuiSvgIcon-root": { color: "#643FBD" } }}
                  />
                </IconButton>
              </NavLink>

              <NavLink
                to="/addbroker"
                className="flex justify-between items-center py-1"
                onClick={() => setToggleDropdown(false)}
              >
                <h1 className="text-primary">Add Broker</h1>
                <IconButton>
                  <ArrowForward
                    fontSize="small"
                    sx={{ "&.MuiSvgIcon-root": { color: "#643FBD" } }}
                  />
                </IconButton>
              </NavLink>

              <div className="pt-5">
                <ColoredButton
                  className="hover:scale-105"
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={onLogoutBtnClick}
                >
                  Logout
                </ColoredButton>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </CSSTransition>

      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
        <Loading />
      </Backdrop>
    </div>
  );
}

export default ProfileDropdown;
