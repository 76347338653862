import React from "react";

function StrategySummary({ title, summary }) {
  return (
    <div className="h-fit bg-primary/30 flex flex-col py-8 px-8 justify-center items-center gap-4 rounded-xl">
      <h1 className="font-semibold text-lg">{title}</h1>
      <div className="flex gap-5">
        <div className="flex flex-col font-medium gap-2">
          {Object.keys(summary).map((key, i) => {
            const result = key.replace(/([A-Z])/g, " $1");
            const finalResult =
              result.charAt(0).toUpperCase() + result.slice(1);
            return <h1 key={i}>{finalResult}:</h1>;
          })}
        </div>
        <div className="flex flex-col gap-2">
          {Object.values(summary).map((value, i) => {
            return <h1 key={i}>{value !== "" ? value : "Not set"}</h1>;
          })}
        </div>
      </div>
    </div>
  );
}

export default StrategySummary;
