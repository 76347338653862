import axios from "axios";

const BASE_URL = "https://api.tradebuddys.com";
const token = JSON.parse(localStorage.getItem("userAuthToken"))?.userToken?.accessToken;

const axiosInstance = axios.create({
    baseURL: BASE_URL
});

axiosInstance.defaults.headers.common['Authorization'] = token !== undefined ? 'Bearer ' + token : '';
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.timeout = 10000; 

export default axiosInstance;