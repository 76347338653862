import React from "react";

function MetricCard({ icon, title, content }) {
  return (
    <div className="p-8">
      <div className="flex justify-evenly items-center gap-2">
        {icon}
        <h1 className="font-medium text-2xl">{title}</h1>
      </div>
      <h1 className="text-xl mt-5">{content}</h1>
    </div>
  );
}

export default MetricCard;
