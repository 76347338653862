import { IconButton } from "@mui/material";
import React from "react";

function NavItem({ collapsed, menuItem, iconComponent, active }) {
  return (
    <div
      className={`flex pr-4 mb-4 ${
        collapsed ? "hover:bg-gray-400/25 rounded-2xl hover:cursor-pointer" : ""
      }`}
    >
      <div
        className={`w-1.5 h-9 self-center ${active ? "bg-white" : ""}`}
      ></div>
      <div className="flex items-center ml-1">
        <IconButton>{iconComponent}</IconButton>
        <h1 className="ml-2.5 font-lg text-white">{menuItem}</h1>
      </div>
    </div>
  );
}

export default NavItem;
