import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ColoredButton from "./ColoredButton";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import StrategiesTable from "./StrategiesTable";
import OpenStrategiesTable from "./OpenStrategiesTable";
import { useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <h1>{children}</h1>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const StyledTabs = styled(Tab)({
  fontFamily: "Poppins",
  fontSize: "16px",
});

export default function NavTabs({ strategiesData, webSocketObj }) {
  const [value, setValue] = useState(0);
  const navigate = useNavigate(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "full" }}>
      <Box
        sx={{ borderBottom: 2, borderColor: "divider" }}
        className="flex justify-between"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
        >
          <StyledTabs label="OPEN STRATEGIES" {...a11yProps(0)} />
          <StyledTabs label="SAVED STRATEGIES" {...a11yProps(1)} />
        </Tabs>
        <div>
          <ColoredButton
            variant="contained"
            size="small"
            onClick={() => navigate("addStrategy")}
          >
            Add strategy
          </ColoredButton>
        </div>
      </Box>
      <TabPanel value={value} index={0}>
        <OpenStrategiesTable
          openStrategies={strategiesData
            .filter((strategy) => strategy.status === "placed")
            .map((strategy) => {
              return {
                ...strategy,
                instrument: strategy.trades[0].instrument,
                lots: strategy.trades[0].lots,
              };
            })}
          webSocketObj={webSocketObj}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <StrategiesTable
          savedStrategies={strategiesData.map((strategy) => {
            return {
              ...strategy,
              instrument: strategy.trades[0]?.instrument,
              lots: strategy.trades[0]?.lots,
            };
          })}
          webSocketObj={webSocketObj}
        />
      </TabPanel>
    </Box>
  );
}
