import React, { useState } from "react";
import ColoredButton from "../Components/ColoredButton";
import TextInput from "../Components/TextInput";
import { Alert, Backdrop, Snackbar } from "@mui/material";
import Loading from "../Components/Loading";
import axiosInstance from "../Configuration/axiosConfig";
import { useNavigate } from "react-router-dom";

function AddAngelBroker() {
  const [brokerDetails, setBrokerDetails] = useState({
    clientId: "",
    remarks: "",
    apiKey: "",
    secretKey: "",
  });

  const [displayMsg, setDisplayMsg] = useState({
    type: "success",
    msg: "",
  });

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;

    setBrokerDetails((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  const onSaveBtnClick = async () => {
    setLoading(true);
    try {
        const response = await axiosInstance.post('trades/angel/', {
            userid: brokerDetails.clientId,
            api_key: brokerDetails.apiKey,
            api_secret: brokerDetails.secretKey,
            user: JSON.parse(localStorage.getItem("loggedInUser")).pk
        });

        if(response.status === 201) {
            setSnackBarOpen(true);
            setDisplayMsg({msg: "Broker setup successfully", type: "success"});
            setTimeout(() => {
                navigate("/addBroker");
            }, 1500);
        }
    } catch(err) {
        let msg = '';
        if(err.request.status === 400) {
            err.response.data?.detail ? msg = err.response.data.detail 
            : Object.entries(err.response.data).forEach(([key, value]) => {
                msg += key + ': ' + value + "\n";
            });
        } 
        else if(err.request.status === 401 || err.request.status === 404)
            msg = err.message;
        
        setSnackBarOpen(true);
        setDisplayMsg({msg: msg, type: "error"});
    } finally {
        setLoading(false);
    }
    // var res = await addAngelBroker(brokerDetails);
    // setLoading(false);
    // if (res.status === 201) {
    //   setSnackBarOpen(true);
    //   setDisplayMsg({ msg: "Broker setup successfully", type: "success" });
    // } else if (res.status === 400) {
    //   setSnackBarOpen(true);
    //   await res
    //     .json()
    //     .then((data) => setDisplayMsg({ msg: data.detail, type: "error" }));
    // } else {
    //   setSnackBarOpen(true);
    //   setDisplayMsg({ msg: "Broker setup failed", type: "error" });
    // }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  return (
    <div className="flex flex-col gap-8 overflow-y-auto h-[94vh]">
      <div className="flex justify-center items-center w-full gradient-bg py-5">
        <h1 className="text-5xl text-white font-semibold">Angel One</h1>
      </div>

      <div className="flex flex-col gap-5 overflow-y-auto">
        <h1 className="text-xl font-bold">Follow the steps:</h1>
        <div>
          <h1 className="mb-2.5 font-semibold">
            1. Enter your Angel One Client ID in the text box below.
          </h1>
          <TextInput
            name="clientId"
            value={brokerDetails.clientId}
            sx={{ "&.MuiTextField-root": { width: "33%" } }}
            size="small"
            placeholder="Enter your Client ID"
            onChange={handleChange}
          />
        </div>
        <div className="w-2/6">
          <h1 className="mb-2.5 font-semibold">Remarks (Optional)</h1>
          <TextInput
            name="remarks"
            value={brokerDetails.remarks}
            fullWidth
            size="small"
            placeholder="Enter remarks"
            onChange={handleChange}
          />
        </div>
        <div>
          <h1 className="mb-2.5 font-semibold">
            3. Generate Redirect & Postback URLs
          </h1>
          <ColoredButton
            className="hover:scale-105"
            variant="contained"
            size="small"
          >
            Generate Links
          </ColoredButton>
          <div className="mt-2.5">
            <h1>Redirect URL: </h1>
            <h1>Postback URL: </h1>
          </div>
        </div>
        <div>
          <h1 className="mb-2.5 font-semibold">
            4. Copy the API Key and API secret and paste in below and click
            Save.
          </h1>
          <div className="flex flex-col w-2/6 gap-3">
            <TextInput
              name="apiKey"
              value={brokerDetails.apiKey}
              fullWidth
              size="small"
              placeholder="API Key"
              onChange={handleChange}
            />
            <TextInput
              name="secretKey"
              value={brokerDetails.secretKey}
              fullWidth
              size="small"
              placeholder="API Secret"
              onChange={handleChange}
            />
          </div>
        </div>
        <ColoredButton
          className="hover:scale-105 w-fit"
          variant="contained"
          size="small"
          onClick={onSaveBtnClick}
        >
          Save
        </ColoredButton>
      </div>
      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
        <Loading />
      </Backdrop>
      <Snackbar
        open={snackBarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert
          variant="filled"
          onClose={handleSnackbarClose}
          severity={displayMsg.type}
          sx={{ width: "100%" }}
        >
          {displayMsg.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AddAngelBroker;
