import { Button } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ColoredButton from "../Components/ColoredButton";

function LandingPageHeader() {
  const [activePage, setActivePage] = useState("Home");
  const navigate = useNavigate();

  return (
    <div className="px-32 py-10 flex justify-between items-center">
      <div>
        <h1 className="text-primary font-semibold text-4xl">Trade Buddys</h1>
      </div>
      <div>
        <ul className="flex gap-16 text-lg font-medium">
          <li className={activePage !== "Home" ? "text-[#A6A6A6]" : ""}>
            <Link to="/" onClick={() => setActivePage("Home")}>
              Home
            </Link>
          </li>
          <li className={activePage !== "Pricing" ? "text-[#A6A6A6]" : ""}>
            <Link to="/" onClick={() => setActivePage("Pricing")}>
              Pricing
            </Link>
          </li>
          <li className={activePage !== "FAQs" ? "text-[#A6A6A6]" : ""}>
            <Link to="/" onClick={() => setActivePage("FAQs")}>
              FAQs
            </Link>
          </li>
          <li className={activePage !== "Team" ? "text-[#A6A6A6]" : ""}>
            <Link to="/" onClick={() => setActivePage("Team")}>
              Team
            </Link>
          </li>
        </ul>
      </div>
      <div className="flex gap-5">
        <Button
          sx={{
            color: "#643FBD",
            borderColor: "#643FBD",
            borderRadius: "10px",
            "&:hover": {
              borderColor: "#643FBD",
              backgroundColor: "#DED8F0",
            },
          }}
          variant="outlined"
          onClick={() => navigate("/login")}
        >
          Login
        </Button>
        <ColoredButton
          sx={{ borderRadius: "10px" }}
          variant="contained"
          onClick={() => navigate("/signup")}
        >
          Signup
        </ColoredButton>
      </div>
    </div>
  );
}

export default LandingPageHeader;
