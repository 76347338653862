class Instruments {
  constructor(stocksData, derivativesData) {
    this.stocksData = stocksData;
    this.derivativesData = derivativesData;
  }

  saveData(data) {
    var stocksData = data.filter((item) => item[0] === "NSECM");
    var derivativesData = data.filter((item) => item[0] === "NSEFO");
    this.stocksData = stocksData;
    this.derivativesData = derivativesData;
  }

  getData(segment) {
    if (segment === "NSECM") {
      return this.stocksData;
    } else {
      return this.derivativesData;
    }
  }
}

let instrumentData = new Instruments();

export default instrumentData;
