import React from 'react'
import { MutatingDots } from  'react-loader-spinner'

function Loading() {
  return (
    <MutatingDots 
        height="100"
        width="100"
        color="#643FBD"
        secondaryColor= '#643FBD'
        radius='12.5'
        ariaLabel="mutating-dots-loading"
        visible={true}
    />
  )
}

export default Loading