import React from "react";
import { useNavigate } from "react-router-dom";
import ColoredButton from "./ColoredButton";
import { Alert } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

function BrokerBanner({ brokerName, brokerLogo, name, isSetup }) {
  const navigate = useNavigate();

  const onAddBtnClick = () => {
    navigate(`/addBroker/${name}`);
  };

  return (
    <div className="shadow-xl p-7 flex flex-col">
      <img
        src={brokerLogo}
        alt="Zerodha logo"
        className="w-36 h-36 self-center object-contain"
      />
      <h1 className="font-bold text-lg mb-3">{brokerName}</h1>
      {isSetup ? (
        <Alert
          variant="filled"
          severity="success"
          sx={{ fontFamily: "Poppins" }}
          icon={<CheckCircleOutline fontSize="small" />}
        >
          Setup successfully
        </Alert>
      ) : (
        <ColoredButton
          onClick={onAddBtnClick}
          className="hover:scale-105"
          variant="contained"
          size="small"
        >
          Add broker
        </ColoredButton>
      )}
    </div>
  );
}

export default BrokerBanner;
