import { ArrowDownward, ArrowUpward, ExitToApp } from "@mui/icons-material";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid";
import * as React from "react";
import NoRowsOverlay from "./NoRowsOverlay";
import ColoredButton from "./ColoredButton";
import StyledDataGrid from "./StyledDataGrid";
import { useState } from "react";
import {
  Alert,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Snackbar,
} from "@mui/material";
import Loading from "./Loading";
import TradeLegsDetails from "./TradeLegsDetails";
import { useNavigate } from "react-router-dom";
import OutlinedButton from "./OutlinedButton";
import ConfirmDialog from "./ConfirmDialog";
import { useCallback } from "react";
import { useEffect } from "react";
import axiosInstance from "../Configuration/axiosConfig";

function renderPL(props) {
  const { value } = props;

  return (
    <h1
      className={`font-semibold ${
        value[0] === "+" ? "text-green-500" : "text-red-500"
      }`}
    >
      {value}
    </h1>
  );
}

// https://socket.io/how-to/use-with-react

export default function OpenStrategiesTable({
  openStrategies,
  webSocketObj: ws,
}) {
  const columns = [
    {
      field: "name",
      headerName: "Strategy Name",
      headerAlign: "center",
      renderCell: renderStrategyName,
      align: "center",
      width: 200,
    },
    {
      field: "instrument",
      headerName: "Instrument",
      width: 150,
    },
    {
      field: "broker",
      headerName: "Client Id/Broker",
      width: 180,
    },
    {
      field: "lots",
      headerName: "Lots",
      width: 135,
    },
    {
      field: "csop",
      type: "number",
      headerName: "CSOP",
      width: 135,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "target",
      headerName: "Target",
      editable: true,
      width: 135,
      valueGetter: (params) => {
        const target = parseFloat(params.row.isop) + parseFloat(params.value);
        return Math.round(target * 100) / 100;
      },
      align: "left",
      headerAlign: "left",
    },
    {
      field: "sl",
      headerName: "Stop Loss",
      editable: true,
      width: 135,
      valueGetter: (params) => {
        const stopLoss = parseFloat(params.row.isop) - parseFloat(params.value);
        return Math.round(stopLoss * 100) / 100;
      },
      align: "left",
      headerAlign: "left",
    },
    {
      field: "pl",
      headerName: "Current P/L",
      width: 135,
      renderCell: renderPL,
      align: "center",
      headerAlign: "left",
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ExitToApp sx={{ color: "black" }} />}
          label="Exit"
          onClick={handleExitClick(params.id)}
        />,
      ],
    },
  ];

  function renderStrategyName(props) {
    const { value, id } = props;

    return (
      <div>
        <Link
          sx={{
            color: "black",
            fontFamily: "Poppins",
            fontWeight: "bold",
            textDecoration: "none",
            borderBottom: "1px solid black",
          }}
          component="button"
          onClick={() => showStrategyLegDetails(id)}
          variant="body2"
        >
          {value}
        </Link>
      </div>
    );
  }

  const handleExitClick = (id) => async () => {
    var row = rows.find((row) => row.id === id);
    setLoading(true);
    try {
        const response = await axiosInstance.put('trades/strategy/', {...row, exit_market: true});

        if(response.status === 200) {
            setSnackBarOpen(true);
            setDisplayMsg({msg: "Exited the strategy", type: "success"});
            setTimeout(() => {
                navigate(0);
            }, 1000);
        }

    } catch(err) {
        setSnackBarOpen(true);
        setDisplayMsg({msg: err.message, type: "error"});
    } finally {
        setLoading(false);
    }
  };

  const [rows, setRows] = useState(openStrategies);

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [loading, setLoading] = useState(false);

  const [displayMsg, setDisplayMsg] = useState({
    type: "success",
    msg: "",
  });

  const [tradeLegsOpen, setTradeLegsOpen] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [promiseArguments, setPromiseArguments] = useState(null);

  const [confirmMsg, setConfirmMsg] = useState("");

  const apiRef = useGridApiRef();

  const navigate = useNavigate();

  const [tradeLegs, setTradeLegs] = useState({
    strategyName: "",
    legs: [],
  });

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const onExitBtnClick = () => {
    setDialogOpen(true);
  };

  const onYesBtnClick = async () => {
    setLoading(true);
    var selectedRows = apiRef.current.getSelectedRows();
    var promiseArray = [];
    for(var row of selectedRows) 
        promiseArray.push(axiosInstance.put('trades/strategy/', {...row[1], exit_market: true}));

    Promise.all(promiseArray).then(() => {
        setSnackBarOpen(true);
        setDisplayMsg({msg: "Exited the selected strategies", type: "success"});
        setTimeout(() => {
            navigate(0);
        }, 1000);
    }).catch((err) => {
        setSnackBarOpen(true);
        setDisplayMsg({msg: err.message, type: "error"});
    })
    setLoading(false);
    setDialogOpen(false);
  };

  const showStrategyLegDetails = async (id) => {
    var strategy = openStrategies.find((strategy) => strategy.id === id);
    setTradeLegs({ name: strategy.name, legs: strategy.trades });
    setTradeLegsOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  function computeMutation(newRow, oldRow) {
    if (newRow.target !== oldRow.target) {
      setConfirmMsg(
        `Changing target from ${oldRow.target} to ${newRow.target}`
      );
      return true;
    }
    if (newRow.sl !== oldRow.sl) {
      setConfirmMsg(`Changing stop loss from ${oldRow.sl} to ${newRow.sl}`);
      return true;
    }
    return null;
  }

  const processRowUpdate = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow);
        }
      }),
    []
  );

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow);
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, resolve } = promiseArguments;
    setLoading(true);
    try {
        const { user, id, exit_market, target, sl } = newRow; 
        const response = await axiosInstance.put('trades/strategy/', {user, id, exit_market, target, sl});

        if(response.status === 200) {
            resolve(newRow);
            setSnackBarOpen(true);
            setDisplayMsg({msg: "Strategy updated successfully", type: "success"});
            setPromiseArguments(null);
            navigate(0);
        }
    } catch(err) {
        resolve(oldRow);
        setSnackBarOpen(true);
        setDisplayMsg({msg: err.message, type: "error"});
        setPromiseArguments(null);
    } finally {
        setLoading(false);
    }
  };

  const restartConn = (strategies) => {
    ws = new WebSocket("wss://tradebuddys.com/ws/socket-server/");
    var trade_ids = [];

    ws.onopen = () => {
      strategies.forEach((strategy) => {
        trade_ids.push(strategy.id);
      });

      ws.send(
        JSON.stringify({
          id: trade_ids,
        })
      );
    };

    receivSocketData();
  };

  const receivSocketData = () => {
    ws.onmessage = ({ data }) => {
      data = JSON.parse(data);
      data?.data.forEach((strategy) => {
        if (strategy.detail === "ok") {
          if (strategy.status === "executed") {
            setSnackBarOpen(true);
            setDisplayMsg({
              type: "success",
              msg: `Strategy with Id ${strategy.id} is squared off since it reached its target or stop loss levels`,
            });
            const updatedRows = rows.filter(
              (row) => row.id !== parseInt(strategy.id)
            );
            setRows(updatedRows);
            ws.close();
            restartConn(updatedRows);
          } else {
            setRows((prevData) => {
              const newData = [...prevData];
              const objectIdx = newData.findIndex(
                (row) => row.id === parseInt(strategy.id)
              );
              newData[objectIdx].csop = strategy.csop;
              newData[objectIdx].pl = strategy.pl;
              newData[objectIdx].status = strategy.status;
              return newData;
            });
          }
        }
      });
    };
  };

  const sendAndReceiveSocketData = () => {
    if (!ws) {
      return;
    }

    var trade_ids = [];
    ws.onopen = () => {
      openStrategies.forEach((strategy) => {
        trade_ids.push(strategy.id);
      });

      ws.send(
        JSON.stringify({
          id: trade_ids,
        })
      );
    };

    receivSocketData();
  };

  useEffect(() => {
    sendAndReceiveSocketData();
  }, []);

  return (
    <div className="w-full flex flex-col gap-5 h-[400px]">
      <StyledDataGrid
        rows={rows}
        columns={columns}
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay msg="You don't have any open strategy yet" />
          ),
          columnSortedAscendingIcon: () => (
            <ArrowDownward fontSize="small" sx={{ color: "black" }} />
          ),
          columnSortedDescendingIcon: () => (
            <ArrowUpward fontSize="small" sx={{ color: "black" }} />
          ),
        }}
        disableRowSelectionOnClick
        checkboxSelection
        disableColumnMenu
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        apiRef={apiRef}
        processRowUpdate={processRowUpdate}
      />
      <ConfirmDialog
        promiseArgs={promiseArguments}
        msg={confirmMsg}
        handleNo={handleNo}
        handleYes={handleYes}
      />

      <div>
        <ColoredButton
          disabled={!rowSelectionModel.length > 0}
          variant="contained"
          size="small"
          onClick={onExitBtnClick}
        >
          Exit Strateg
          {rowSelectionModel.length > 1 ? "ies" : "y"}
        </ColoredButton>
      </div>

      <TradeLegsDetails
        open={tradeLegsOpen}
        setOpen={setTradeLegsOpen}
        tradeLegs={tradeLegs}
      />

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle sx={{ fontFamily: "Poppins" }}>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontFamily: "Poppins" }}>
            Are you sure you want to exit the selected strategies?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutlinedButton onClick={onYesBtnClick}>Yes</OutlinedButton>
          <OutlinedButton onClick={() => setDialogOpen(false)}>
            No
          </OutlinedButton>
        </DialogActions>
      </Dialog>

      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
        <Loading />
      </Backdrop>

      <Snackbar
        open={snackBarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert
          variant="filled"
          onClose={handleSnackbarClose}
          severity={displayMsg.type}
          sx={{ width: "100%" }}
        >
          {displayMsg.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
