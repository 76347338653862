import React, { useState } from "react";
import ColoredButton from "../Components/ColoredButton";
import TextInput from "../Components/TextInput";
import { Link, useNavigate } from "react-router-dom";
import zerodhaBrokerSetup from "../assets/zerodha-broker-setup.png";
import zerodhaBrokerSetup2 from "../assets/zerodha-broker-setup-2.png";
import { Alert, Backdrop, Snackbar } from "@mui/material";
import Loading from "../Components/Loading";
import axiosInstance from "../Configuration/axiosConfig";

function AddZerodhaBroker() {
  const [brokerDetails, setBrokerDetails] = useState({
    clientId: "",
    remarks: "",
    apiKey: "",
    secretKey: "",
  });

  const [displayMsg, setDisplayMsg] = useState({
    type: "success",
    msg: "",
  });

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;

    setBrokerDetails((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  const onSaveBtnClick = async () => {
    setLoading(true);
    try {
        const response = await axiosInstance.post('trades/zerodha/', {
            userid: brokerDetails.clientId,
            api_key: brokerDetails.apiKey,
            api_secret: brokerDetails.secretKey,
            user: JSON.parse(localStorage.getItem("loggedInUser")).pk
        });

        if(response.status === 201) {
            setSnackBarOpen(true);
            setDisplayMsg({msg: "Broker setup successfully", type: "success"});
            setTimeout(() => {
                navigate("/addBroker");
            }, 1500);
        }
    } catch(err) {
        let msg = '';
        if(err.request.status === 400) {
            err.response.data?.detail ? msg = err.response.data.detail 
            : Object.entries(err.response.data).forEach(([key, value]) => {
                msg += key + ': ' + value + "\n";
            });
        } 
        else if(err.request.status === 401 || err.request.status === 404)
            msg = err.message;
        
        setSnackBarOpen(true);
        setDisplayMsg({msg: msg, type: "error"});
    } finally {
        setLoading(false);
    }
    // var res = await addZerodhaBroker(brokerDetails);
    // setLoading(false);
    // if (res.status === 201) {
    //   setSnackBarOpen(true);
    //   setDisplayMsg({ msg: "Broker setup successfully", type: "success" });
    // } else if (res.status === 400) {
    //   setSnackBarOpen(true);
    //   await res
    //     .json()
    //     .then((data) => setDisplayMsg({ msg: data.detail, type: "error" }));
    // } else {
    //   setSnackBarOpen(true);
    //   setDisplayMsg({ msg: "Broker setup failed", type: "error" });
    // }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  return (
    <div className="flex flex-col gap-8 overflow-y-auto h-[94vh] pb-4">
      <div className="flex justify-center items-center w-full gradient-bg py-5">
        <h1 className="text-5xl text-white font-semibold">Zerodha</h1>
      </div>

      <div className="flex flex-col gap-5 overflow-y-auto">
        <h1 className="text-xl font-bold">Follow the steps:</h1>
        <h1>
          1. Setup Zerodha TOTP if you haven't already. Find instructions to
          setup TOTP here:{" "}
          <Link
            to="https://support.zerodha.com/category/your-zerodha-account/login-credentials/login-credentials-of-trading-platforms/articles/time-based-otp-setup"
            target="_blank"
          >
            {" "}
            <span className="text-primary font-medium underline">
              Zerodha TOTP setup
            </span>
          </Link>
        </h1>
        <div className="w-2/6">
          <h1 className="mb-2.5 font-semibold">
            2. Enter your 6-digit Client ID
          </h1>
          <TextInput
            name="clientId"
            value={brokerDetails.clientId}
            fullWidth
            size="small"
            placeholder="Enter your Client ID"
            onChange={handleChange}
          />
        </div>
        <div className="w-2/6">
          <h1 className="mb-2.5 font-semibold">Remarks (Optional)</h1>
          <TextInput
            name="remarks"
            value={brokerDetails.remarks}
            fullWidth
            size="small"
            placeholder="Enter remarks"
            onChange={handleChange}
          />
        </div>
        <div>
          <h1 className="mb-2.5 font-semibold">
            3. Generate Redirect & Postback URLs
          </h1>
          <ColoredButton
            className="hover:scale-105"
            variant="contained"
            size="small"
          >
            Generate Links
          </ColoredButton>
          <div className="mt-2.5">
            <h1>Redirect URL: </h1>
            <h1>Postback URL: </h1>
          </div>
        </div>
        <div>
          <h1 className="mb-2.5 font-semibold">
            4. Copy the API Key and API secret and paste in below and click
            Save.
          </h1>
          <div className="flex flex-col w-2/6 gap-3">
            <TextInput
              name="apiKey"
              value={brokerDetails.apiKey}
              fullWidth
              size="small"
              placeholder="API Key"
              onChange={handleChange}
            />
            <TextInput
              name="secretKey"
              value={brokerDetails.secretKey}
              fullWidth
              size="small"
              placeholder="API Secret"
              onChange={handleChange}
            />
          </div>
        </div>
        <ColoredButton
          className="hover:scale-105 w-fit"
          variant="contained"
          size="small"
          onClick={onSaveBtnClick}
        >
          Save
        </ColoredButton>

        <div className="flex flex-col gap-3">
          <h1 className="text-xl font-bold">Instructions:</h1>
          <h1>
            1. Setup Zerodha TOTP if you haven’t already. Find instructions to
            setup TOTP here:{" "}
            <Link
              to="https://support.zerodha.com/category/your-zerodha-account/login-credentials/login-credentials-of-trading-platforms/articles/time-based-otp-setup"
              target="_blank"
            >
              {" "}
              <span className="text-primary font-medium underline">
                Zerodha TOTP setup
              </span>
            </Link>
          </h1>
          <h1>
            2. Sign up and login to{" "}
            <Link to="https://developers.kite.trade/signup" target="_blank">
              {" "}
              <span className="text-primary font-medium underline">
                developers.kite.trade
              </span>
            </Link>{" "}
            with your Zerodha credentials.
          </h1>
          <h1>3. Click ‘Create new app’ to create a new API subscription.</h1>
          <h1>4. Select app type ‘Connect’.</h1>
          <h1>5. Fill in a random name and enter your Zerodha client ID.</h1>
          <img
            src={zerodhaBrokerSetup}
            alt="Zerodha broker setup"
            height="400px"
            width="400px"
          />
          <h1>
            6. Copy the Redirect URL and Postback URL generated in Step 3, into
            the ‘Create a new app’ section.
          </h1>
          <h1>
            7. Once all of the above details are added to the form, click
            ‘CREATE’.
          </h1>
          <h1>
            8. After the succesful creation of the app, go to ‘My Apps’ and
            click on the ‘Random name’ app that you have created.
          </h1>
          <h1>
            9. Go to ‘My Apps’ and click on the ‘Random name’ app that you have
            created and you will see a screen with the API key and API secret.
          </h1>
          <img
            src={zerodhaBrokerSetup2}
            alt="Zerodha broker setup 2"
            height="400px"
            width="400px"
          />
          <h1>
            10. Copy the API Key and API secret and paste in the text boxes in
            Step 4 and click ‘Save’. You are done with the setup.
          </h1>
        </div>
      </div>
      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
        <Loading />
      </Backdrop>
      <Snackbar
        open={snackBarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert
          variant="filled"
          onClose={handleSnackbarClose}
          severity={displayMsg.type}
          sx={{ width: "100%" }}
        >
          {displayMsg.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AddZerodhaBroker;
