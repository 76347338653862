import styled from "@emotion/styled";
import { DataGrid } from "@mui/x-data-grid";

const StyledDataGrid = styled(DataGrid)(() => ({
  fontFamily: "Poppins",
  borderRadius: "20px",
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#643FBD",
    color: "white",
    fontSize: "16px",
    fontWeight: 700,
  },
  "& .MuiDataGrid-columnHeader .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg":
    {
      backgroundColor: "black",
      borderColor: "black",
    },
  "& .MuiCheckbox-root svg": {
    width: 14,
    height: 14,
    border: "1px solid black",
    background: "transparent",
    borderRadius: 2,
  },
  "& .MuiCheckbox-root svg path": {
    display: "none",
  },
  "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
    backgroundColor: "#643FBD",
    borderColor: "#643FBD",
  },
  "& .strategy-pastExpiry": {
    backgroundColor: "#D2D6DD",
    "&:hover": {
      backgroundColor: "#C2C7CF",
    },
  },
}));

export default StyledDataGrid;
