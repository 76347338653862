import React, { useEffect } from "react";
import StrategySummary from "./StrategySummary";
import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Zoom,
} from "@mui/material";
import TextInput from "./TextInput";
import { useStateWithCallback } from "../HelperMethods/useStateWithCallback";
import { HelpOutline } from "@mui/icons-material";

function ConfirmStrategy({ strategyDetails, setStrategyDetails }) {
  let { positions: _, ...rest } = strategyDetails;

  const [profitability, setProfitability] = useStateWithCallback(
    {
      isop: strategyDetails.isop,
      targetDelta: strategyDetails.targetDelta,
      stopLossDelta: strategyDetails.stopLossDelta,
    },
    (profitability) => {
      var _target =
        parseFloat(strategyDetails.isop) +
        parseFloat(profitability.targetDelta);
      var _stopLoss =
        parseFloat(strategyDetails.isop) -
        parseFloat(profitability.stopLossDelta);

      setStrategyDetails((prevData) => {
        return {
          ...prevData,
          target: Math.round(_target * 100) / 100,
          stopLoss: Math.round(_stopLoss * 100) / 100,
        };
      });
    }
  );

  const handleChange = (event) => {
    const { name, value } = event.target;

    setStrategyDetails((prevData) => {
      return { ...prevData, [name]: value };
    });

    setProfitability((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  useEffect(() => {
    var _isop = 0;
    strategyDetails.positions.forEach((position) => {
      if (position.position === "buy") _isop += parseFloat(position.entryPrice);
      else _isop -= parseFloat(position.entryPrice);
    });

    setStrategyDetails((prevData) => {
      return { ...prevData, isop: Math.round(_isop * 100) / 100 };
    });
  }, []);

  return (
    <div className="flex w-full gap-5 justify-between">
      <div className="flex flex-col gap-4 overflow-y-auto">
        <div className="flex flex-col gap-2">
          <label className="font-medium">
            Select Delivery<span className="text-red-700">*</span>
          </label>
          <RadioGroup
            row
            name="delivery"
            value={strategyDetails.delivery}
            onChange={handleChange}
          >
            <FormControlLabel
              value="mis"
              disableTypography
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": { color: "#643FBD" },
                  }}
                  size="small"
                />
              }
              label="Intraday (MIS)"
            />
            <FormControlLabel
              value="nrml"
              disableTypography
              control={
                <Radio
                  sx={{ "&.Mui-checked": { color: "#643FBD" } }}
                  size="small"
                />
              }
              label="Overnight (NRML)"
            />
          </RadioGroup>
        </div>
        <div className="flex flex-col gap-4">
          <label className="font-medium">
            Target Delta<span className="text-red-700">*</span>
          </label>
          <TextInput
            name="targetDelta"
            value={strategyDetails.targetDelta}
            type="number"
            size="small"
            placeholder="Enter Target Delta"
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
        <div className="flex flex-col gap-4">
          <label className="font-medium">
            Stoploss Delta<span className="text-red-700">*</span>
          </label>
          <TextInput
            name="stopLossDelta"
            type="number"
            value={strategyDetails.stopLossDelta}
            size="small"
            placeholder="Enter Stoploss Delta"
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h1 className="font-medium">Profitability Calculated</h1>
        <div className="flex gap-20">
          <div className="flex flex-col gap-3">
            <h1>
              Current Price{" "}
              <Tooltip
                disableFocusListener
                TransitionComponent={Zoom}
                title="Net sum of premium of all strike prices (Debit - Credit)"
              >
                <IconButton sx={{ padding: 0 }}>
                  <HelpOutline fontSize="small" />
                </IconButton>
              </Tooltip>
            </h1>
            <h1>
              {strategyDetails.isop >= 0 ? "(Debit) " : "Credit "}
              {strategyDetails.isop}
            </h1>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-gray-600">Target</h1>
            <h1>
              {strategyDetails.target >= 0 ? "(Debit) " : "Credit "}
              {strategyDetails.target}
            </h1>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-gray-600">Stop Loss</h1>
            <h1>
              {strategyDetails.stopLoss >= 0 ? "(Debit) " : "Credit "}
              {strategyDetails.stopLoss}
            </h1>
          </div>
        </div>
      </div>
      <div className="overflow-y-auto">
        <StrategySummary title="Strategy Summary" summary={rest} />
      </div>
    </div>
  );
}

export default ConfirmStrategy;
