import { createContext, useContext, useEffect, useState } from "react";
import instrumentData from "../Components/InstrumentsData";
import getInstruments from "../HelperMethods/getInstruments";

const ConfigDataContext = createContext();

export const ConfigDataProvider = ({children}) => {
    const [configData, setConfigData] = useState({
        userDetails: {},
        instrumentsData: []
    });

    useEffect(() => {

        const getConfigData = async () => {
            var userDetails = JSON.parse(localStorage.getItem("userDetails"));
            
            var instruments = instrumentData.getData();
            if (!instruments) {
                instruments = await getInstruments(["NSEFO"]);
                var instruments = instruments.result
                .split("\n")
                .map((instrument) => instrument.split("|"));
                instrumentData.saveData(instruments);
            }

            setConfigData({userDetails, instrumentsData: instruments});
        }

        getConfigData();
    }, []);

    return (
        <ConfigDataContext.Provider value = {{configData, setConfigData}}>
            {children}
        </ConfigDataContext.Provider>
    )
}

export const useConfigData = () => useContext(ConfigDataContext);