import React from "react";
import { Chart } from "react-google-charts";

export const data = [
  ["Day", "Sales", "Expenses"],
  ["Mon", 1000, 400],
  ["Tue", 1170, 460],
  ["Wed", 660, 1120],
  ["Thu", 1030, 540],
  ["Fri", 845, 631],
];

export const options = {
  title: "Revenue",
  curveType: "function",
  series: [{ color: "#643FBD" }],
  animation: {
    duration: 1000,
    easing: "out",
    startup: true,
  },
  legend: {
    position: "bottom",
    textStyle: { fontName: "Poppins" },
  },
  hAxis: {
    textStyle: { fontName: "Poppins" },
  },
  vAxis: {
    textStyle: { fontName: "Poppins" },
  },
  titleTextStyle: { fontName: "Poppins" },
};

export function LineGraph() {
  return <Chart chartType="LineChart" data={data} options={options} />;
}
