import { Alert, Backdrop, Snackbar } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ColoredButton from "../Components/ColoredButton";
import Loading from "../Components/Loading";
import TextInput from "../Components/TextInput";
import axiosInstance from "../Configuration/axiosConfig";

function AddFinvasiaBroker() {
  const [brokerDetails, setBrokerDetails] = useState({
    clientId: "",
    password: "",
    vendorCode: "",
    apiSecret: "",
    imei: "",
  });

  const [loading, setLoading] = useState(false);

  const [displayMsg, setDisplayMsg] = useState({
    type: "success",
    msg: "",
  });

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBrokerDetails((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  const onSaveBtnClick = async () => {
    setLoading(true);
    try {
        const response = await axiosInstance.post('trades/shoonya/', {
            userid: brokerDetails.clientId,
            password: brokerDetails.password,
            vendor_code: brokerDetails.vendorCode,
            api_secret: brokerDetails.apiSecret,
            imei: brokerDetails.imei,
            user: JSON.parse(localStorage.getItem("loggedInUser")).pk,
        })

        if(response.status === 201) {
            setSnackBarOpen(true);
            setDisplayMsg({msg: "Broker setup successfully", type: "success"});
            setTimeout(() => {
                navigate("/addBroker");
            }, 1500);
        }
    } catch(err) {
        let msg = '';
        if(err.request.status === 400) {
            err.response.data?.detail ? msg = err.response.data.detail 
            : Object.entries(err.response.data).forEach(([key, value]) => {
                msg += key + ': ' + value + "\n";
            });
        } 
        else if(err.request.status === 401 || err.request.status === 404)
            msg = err.message;
        
        setSnackBarOpen(true);
        setDisplayMsg({msg: msg, type: "error"});
    } finally {
        setLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  return (
    <div className="flex flex-col overflow-y-auto h-[94vh]">
      <div className="flex justify-center items-center w-full gradient-bg py-5">
        <h1 className="text-5xl text-white font-semibold">Finvasia</h1>
      </div>

      <div className="flex-auto flex flex-col gap-3 overflow-y-auto my-7">
        <h1 className="text-xl font-bold">Follow the steps:</h1>
        <div className="w-2/4">
          <h1 className="mb-2.5 font-semibold">1. Finvasia Client ID</h1>
          <TextInput
            name="clientId"
            value={brokerDetails.clientId}
            size="small"
            fullWidth
            placeholder="Enter Client ID"
            onChange={handleChange}
          />
        </div>
        <div className="w-2/4">
          <h1 className="mb-2.5 font-semibold">2. Finvasia password</h1>
          <TextInput
            name="password"
            value={brokerDetails.password}
            type={"password"}
            fullWidth
            size="small"
            placeholder="Enter Password"
            onChange={handleChange}
          />
        </div>
        <div className="w-2/4">
          <h1 className="mb-2.5 font-semibold">4. Vendor Code</h1>
          <TextInput
            name="vendorCode"
            value={brokerDetails.vendorCode}
            fullWidth
            size="small"
            placeholder="Enter Vendor Code"
            onChange={handleChange}
          />
        </div>
        <div className="w-2/4">
          <h1 className="mb-2.5 font-semibold">5. API Secret</h1>
          <TextInput
            name="apiSecret"
            value={brokerDetails.apiSecret}
            fullWidth
            size="small"
            placeholder="Enter API Secret"
            onChange={handleChange}
          />
        </div>
        <div className="w-2/4">
          <h1 className="mb-2.5 font-semibold">6. IMEI</h1>
          <TextInput
            name="imei"
            value={brokerDetails.imei}
            fullWidth
            size="small"
            placeholder="Enter IMEI"
            onChange={handleChange}
          />
        </div>
        <ColoredButton
          className="hover:scale-105 w-fit"
          variant="contained"
          size="small"
          onClick={onSaveBtnClick}
        >
          Save
        </ColoredButton>
        <div className="w-3/4">
          <ul className="list-decimal list-inside">
            <li>
              Go to{" "}
              <Link to="https://prism.finvasia.com">
                <span className="text-primary">https://prism.finvasia.com</span>
              </Link>{" "}
              and login.
            </li>
            <li>
              After logging in, go to{" "}
              <Link to="https://prism.finvasia.com/api">
                <span className="text-primary">https://prism.finvasia.com</span>
              </Link>
            </li>
            <li>
              Copy the API key and Vendor code from the API details page and
              paste it in the broker setup settings above, and click Save.
            </li>
            <li>
              Go to the Broker Login page on Algo App and login. If the setup
              was done correctly, you will be able to successfully login and
              generate the access token.
            </li>
          </ul>
        </div>
      </div>
      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
        <Loading />
      </Backdrop>
      <Snackbar
        open={snackBarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert
          variant="filled"
          onClose={handleSnackbarClose}
          severity={displayMsg.type}
          sx={{ width: "100%" }}
        >
          {displayMsg.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AddFinvasiaBroker;
