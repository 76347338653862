import React, { useEffect, useState } from "react";
import BrokerBanner from "../Components/BrokerBanner";
import ZerodhaLogo from "../assets/zerodha_logo.png";
import FinvasiaLogo from "../assets/finvasia_logo.png";
import AngelOneLogo from "../assets/angelone_logo.png";
import { Backdrop } from "@mui/material";
import Loading from "../Components/Loading";
import axiosInstance from "../Configuration/axiosConfig";

function AddBroker() {
  const [loading, setLoading] = useState(false);

  const [brokersSetup, setBrokersSetup] = useState([]);

  useEffect(() => {
    async function getBrokersSetup() {
        try {
            const response = await axiosInstance.post('auth/user/brokers/');
            if(response.status === 200)
                setBrokersSetup(Object.keys(response.data));
        } catch(err) {
            // Implement new err mechanism in this page
            console.log(err);
        }
    }
    getBrokersSetup();
  }, []);

  return (
    <>
      <div className="flex justify-center items-center py-20 w-full gradient-bg">
        <h1 className="text-6xl text-white font-semibold">Add Broker</h1>
      </div>

      <div className="mt-12 grid grid-cols-3 gap-14">
        <BrokerBanner
          name="zerodha"
          brokerName="Zerodha"
          brokerLogo={ZerodhaLogo}
          isSetup={brokersSetup.includes("zerodha")}
        />
        <BrokerBanner
          name="angelone"
          brokerName="Angel One"
          brokerLogo={AngelOneLogo}
          isSetup={brokersSetup.includes("angelone")}
        />
        <BrokerBanner
          name="finvasia"
          brokerName="Finvasia"
          brokerLogo={FinvasiaLogo}
          isSetup={brokersSetup.includes("finvasia")}
        />
      </div>
      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
        <Loading />
      </Backdrop>
    </>
  );
}

export default AddBroker;
