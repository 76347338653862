import {
  Autocomplete,
  Backdrop,
  FormControlLabel,
  Radio,
  RadioGroup,
  createFilterOptions,
} from "@mui/material";
import React, { useState, useMemo } from "react";
import TextInput from "./TextInput";
import Loading from "./Loading";
import getExpiryDates from "../HelperMethods/getExpiryDates";
import moment from "moment/moment";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.instrumentName,
});

function SpecifyStrategyDetails({
  strategyDetails,
  setStrategyDetails,
  optionInstrumentsList,
}) {
  const [expiries, setExpiries] = useState([]);
  const [lotSize, setLotSize] = useState(0);

  const [loading, setLoading] = useState(false);

  const options = useMemo(() => {
    var _options = optionInstrumentsList.map((item) => {
      return { instrumentName: item[3], instrumentType: item[5] };
    });

    _options = [
      ...new Map(_options.map((v) => [v.instrumentName, v])).values(),
    ];

    return _options;
  }, [optionInstrumentsList]);

  const handleInstrumentChange = async (event, value) => {
    setStrategyDetails((prevData) => {
      return {
        ...prevData,
        instrument: value === null ? null : value.instrumentName,
      };
    });

    if (value != null) {
      var expiryDates = [];
      setLoading(true);

      var instrumentSelected = optionInstrumentsList.find(
        (instrument) => instrument[3] === value.instrumentName
      );

      expiryDates = await getExpiryDates(
        instrumentSelected[5],
        value.instrumentName
      );
      expiryDates = expiryDates.result.map((date) => {
        return moment(date, "YYYY-MM-DDThh:mm:ss").format("DD MMM YYYY");
      });
      expiryDates = expiryDates.sort(
        (a, b) =>
          moment(a, "DD MMM YYYY").format("YYYYMMDD") -
          moment(b, "DD MMM YYYY").format("YYYYMMDD")
      );

      setLotSize(instrumentSelected[12]);
      setExpiries(expiryDates);
      setLoading(false);
    }
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setStrategyDetails((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  return (
    <div className="flex flex-col w-full gap-4 overflow-y-auto">
      <div className="flex flex-col w-2/5 gap-4">
        <label className="font-medium">
          Name your strategy<span className="text-red-700">*</span>
        </label>
        <TextInput
          value={strategyDetails.strategyName}
          name="strategyName"
          size="small"
          fullWidth
          placeholder="Enter an unique name for your strategy"
          autoComplete="off"
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col w-2/5 gap-4">
        <label className="font-medium">
          Select index or stock<span className="text-red-700">*</span>
        </label>
        <Autocomplete
          value={strategyDetails.instrument}
          openOnFocus={true}
          size="small"
          options={options.sort(
            (a, b) => -b.instrumentType.localeCompare(a.instrumentType)
          )}
          onChange={handleInstrumentChange}
          clearOnEscape
          clearOnBlur
          groupBy={(instrument) => instrument.instrumentType}
          getOptionLabel={(instrument) =>
            typeof instrument === "string"
              ? instrument
              : instrument.instrumentName
          }
          noOptionsText="No instruments"
          isOptionEqualToValue={(option, value) =>
            option.instrumentName === value
          }
          filterOptions={filterOptions}
          autoComplete={true}
          renderInput={(params) => (
            <TextInput {...params} placeholder="Select an instrument" />
          )}
        />
      </div>
      <div className="flex flex-col w-2/5 gap-3">
        <label className="font-medium">
          Select Segment<span className="text-red-700">*</span>
        </label>
        <RadioGroup
          row
          name="segment"
          value={strategyDetails.segment}
          onChange={handleChange}
        >
          <FormControlLabel
            value="futures"
            disableTypography
            control={<Radio sx={{ "&.Mui-checked": { color: "#643FBD" } }} />}
            label="Futures"
          />
          <FormControlLabel
            value="options"
            disableTypography
            control={<Radio sx={{ "&.Mui-checked": { color: "#643FBD" } }} />}
            label="Options"
          />
        </RadioGroup>
      </div>
      <div className="flex flex-col w-2/5 gap-4">
        <label className="font-medium">
          Select expiry<span className="text-red-700">*</span>
        </label>
        <Autocomplete
          value={strategyDetails.expiry}
          openOnFocus
          size="small"
          options={expiries}
          onChange={(event, value) => {
            setStrategyDetails((prevData) => {
              return { ...prevData, expiry: value };
            });
          }}
          clearOnEscape
          clearOnBlur
          noOptionsText="No expiries"
          autoComplete
          renderInput={(params) => (
            <TextInput {...params} placeholder="Select an expiry date" />
          )}
        />
      </div>
      <div className="flex flex-col gap-4 w-2/5">
        <label className="font-medium">
          No. of lots<span className="text-red-700">*</span>
        </label>
        <TextInput
          name="lots"
          value={strategyDetails.lots}
          type="number"
          size="small"
          onChange={handleChange}
          placeholder="Select number of lots"
          helperText={`Lot size: ${lotSize}`}
          autoComplete="off"
        />
      </div>
      <div className="flex flex-col w-2/5 gap-2">
        <label className="font-medium">
          Select execution<span className="text-red-700">*</span>
        </label>
        <RadioGroup
          row
          name="execution"
          value={strategyDetails.execution}
          onChange={handleChange}
        >
          <FormControlLabel
            value="buy"
            disableTypography
            control={
              <Radio
                sx={{
                  "&.Mui-checked": { color: "#643FBD" },
                }}
                size="small"
              />
            }
            label="Buy"
          />
          <FormControlLabel
            value="sell"
            disableTypography
            control={
              <Radio
                sx={{ "&.Mui-checked": { color: "#643FBD" } }}
                size="small"
              />
            }
            label="Sell"
          />
        </RadioGroup>
      </div>
      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
        <Loading />
      </Backdrop>
    </div>
  );
}

export default SpecifyStrategyDetails;
