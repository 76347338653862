import {
  Alert,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import React, { useRef, useState } from "react";
import OutlinedButton from "./OutlinedButton";
import ColoredButton from "./ColoredButton";
import Loading from "./Loading";
import TextInput from "./TextInput";
import axiosInstance from "../Configuration/axiosConfig";

function BrokerLoginDialog({ open, setOpen, brokersSetup, setBrokersSetup }) {
  const [loading, setLoading] = useState(false);

  const [totp, setTotp] = useState("");
  const totpRef = useRef();

  const [displayMsg, setDisplayMsg] = useState({
    type: "success",
    msg: "",
  });

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleBrokerLogin = (event) => {
    if (event.target.name === "finvasia") handleFinvasiaBrokerLogin();
  };

  const handleTotpChange = (event) => {
    const { value } = event.target;
    setTotp(value);
  };

  const handleFinvasiaBrokerLogin = async () => {
    setLoading(true);
    try {
        const response = await axiosInstance.post(`trades/shoonya/login?totp=${totp}`);

        if(response.status === 201) {
            setSnackBarOpen(true);
            setDisplayMsg({msg: "Finvasia broker login successful", type: "success"});
            setBrokersSetup(prevData => {
                return {...prevData, 'finvasia': {logged_in: true}}
            });
            setOpen(false);
        }

    } catch(err) {
        setSnackBarOpen(true);
        setDisplayMsg({msg: err.message, type: "error"});
        totpRef.current.focus();

    } finally {
        setLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      setSnackBarOpen(false);
      return;
    }

    setSnackBarOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{ fontFamily: "Poppins", color: "#643FBD", fontWeight: "bold" }}
      >
        Broker Login
      </DialogTitle>
      <DialogContent dividers>
        <form className="flex flex-col gap-3 px-5 py-3" onSubmit={handleBrokerLogin}>
          {brokersSetup &&
            Object.entries(brokersSetup).map((value, i) => {
              return (
                <div
                  key={i}
                  className="flex justify-between gap-8 items-center"
                >
                  <h1 className="font-semibold">
                    {value[0].charAt(0).toUpperCase() + value[0].slice(1)}
                  </h1>
                  {value[0].charAt(0).toUpperCase() + value[0].slice(1) ===
                    "Finvasia" && (
                    <TextInput
                      name="totp"
                      value={totp}
                      autoFocus
                      inputRef={totpRef}
                      size="small"
                      placeholder="Enter TOTP"
                      onChange={handleTotpChange}
                    />
                  )}
                  <OutlinedButton
                    type="submit"
                    variant="outlined"
                    name={value[0]}
                    size="small"
                    disabled={value[1].logged_in}
                    onClick={handleBrokerLogin}
                  >
                    {value[1].logged_in ? "Logged in" : "Login"}
                  </OutlinedButton>
                </div>
              );
            })}
        </form>
      </DialogContent>
      <DialogActions>
        <OutlinedButton onClick={handleClose}>
          Close
        </OutlinedButton>
      </DialogActions>

      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
        <Loading />
      </Backdrop>

      <Snackbar
        open={snackBarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert
          variant="filled"
          onClose={handleSnackbarClose}
          severity={displayMsg.type}
          sx={{ width: "100%" }}
        >
          {displayMsg.msg}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default BrokerLoginDialog;
