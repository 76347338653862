import * as apiConfig from "../Configuration/ApiConfig";
import axios from 'axios';

export default async function getExpiryDates(series, symbol) {
  const response = await axios.get(
    `${apiConfig.BASE_URL}/instruments/instrument/expiryDate?exchangeSegment=2&series=${series}&symbol=${symbol}`,
      {headers: { "Content-Type": "application/json" }}
  );

  return response.data;
}
