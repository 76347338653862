import * as apiConfig from "../Configuration/ApiConfig";

export default async function getEquitySymbol(series, symbol) {
  const response = await fetch(
    `${apiConfig.BASE_URL}/instruments/instrument/symbol?exchangeSegment=1&series=${series}&symbol=${symbol}`,
    {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("APIAuthToken"),
      },
    }
  );

  return await response.json();
}
