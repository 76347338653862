import React, { useEffect, useRef, useState } from "react";
import NavTabs from "../Components/NavTabs";
import { Alert, Backdrop, Snackbar } from "@mui/material";
import Loading from "../Components/Loading";
import axiosInstance from '../Configuration/axiosConfig';

function Strategies() {
  const [loading, setLoading] = useState(false);
  const [displayMsg, setDisplayMsg] = useState({
    type: "success",
    msg: "",
  });
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [strategies, setStrategies] = useState([]);
  var ws = useRef(null);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  const establishWebSocketConn = () => {
    ws.current = new WebSocket("wss://tradebuddys.com/ws/socket-server/");
  };

  useEffect(() => {

    const controller = new AbortController();

    async function getData() {
      setLoading(true);
      try {
        const response = await axiosInstance.get('trades/strategy/?deep=true', {
            signal: controller.signal
        });
        if(response.status === 200)
        {
            setStrategies(response.data);
            establishWebSocketConn();
        }
      } catch(err) {
        setSnackBarOpen(true);
        let msg = '';
        if(err.request.status === 404)
            msg = err.message;
        else if(err.request.status === 401)
            msg = err.response.data.detail;
        setDisplayMsg({
          msg: msg,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    }

    getData();

    return () => {
        controller.abort();
        if(ws.current != null && ws.current.readyState === WebSocket.OPEN)
            ws.current.close()
    }
  }, []);

  if (loading) {
    return (
      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
        <Loading />
      </Backdrop>
    );
  }

  return (
    <>
      <div className="flex justify-center items-center py-10 w-full gradient-bg">
        <h1 className="text-6xl text-white font-semibold">Strategies</h1>
      </div>

      <div className="mt-4">
        <NavTabs strategiesData={strategies} webSocketObj={ws.current} />
      </div>

      <Snackbar
        open={snackBarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert
          variant="filled"
          onClose={handleSnackbarClose}
          severity={displayMsg.type}
        >
          {displayMsg.msg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Strategies;
