import {
  Autocomplete,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import StrategyPositions from "./StrategyPositions";
import TextInput from "./TextInput";
import ColoredButton from "./ColoredButton";
import moment from "moment";
import getQuote from "../HelperMethods/getQuote";
import OutlinedButton from "./OutlinedButton";
import Loading from "./Loading";
import StrategySummary from "./StrategySummary";
import getEquitySymbol from "../HelperMethods/GetEquitySymbol";

const filterOptions = createFilterOptions({
  matchFrom: "start",
});

function AddStrategies({
  strategyDetails,
  setStrategyDetails,
  optionInstrumentsList,
}) {
  const [open, setOpen] = useState(false);
  const [positions, setPositions] = useState([]);
  const [strikePrices, setStrikePrices] = useState({
    strikePrices: [],
    nearestStrikePrice: 0,
  });
  const [loading, setLoading] = useState(false);
  const [instrumentSummary, setInstrumentSummary] = useState({});

  const strategies = [
    {
      name: "Straddle",
      legsDetails: [
        {
          strikePrice:
            strikePrices.strikePrices.length > 0
              ? strikePrices.nearestStrikePrice
              : 0,
          optionType: 3,
          position: strategyDetails.execution,
          entryPrice: 0.0,
        },
        {
          strikePrice:
            strikePrices.strikePrices.length > 0
              ? strikePrices.nearestStrikePrice
              : 0,
          optionType: 4,
          position: strategyDetails.execution,
          entryPrice: 0.0,
        },
      ],
    },
    {
      name: "Strangle",
      legsDetails: [
        {
          strikePrice:
            strikePrices.strikePrices.length > 0
              ? strikePrices.nearestStrikePrice
              : 0,
          optionType: 3,
          position: strategyDetails.execution,
          entryPrice: 0.0,
        },
        {
          strikePrice:
            strikePrices.strikePrices.length > 0
              ? strikePrices.nearestStrikePrice
              : 0,
          optionType: 4,
          position: strategyDetails.execution,
          entryPrice: 0.0,
        },
      ],
    },
    {
      name: "Spread",
      legsDetails: [
        {
          strikePrice:
            strikePrices.strikePrices.length > 0
              ? strikePrices.nearestStrikePrice
              : 0,
          optionType: 3,
          position: strategyDetails.execution,
          entryPrice: 0.0,
        },
        {
          strikePrice:
            strikePrices.strikePrices.length > 0
              ? strikePrices.nearestStrikePrice
              : 0,
          optionType: 3,
          position: strategyDetails.execution === "buy" ? "sell" : "buy",
          entryPrice: 0.0,
        },
      ],
      type: "call",
    },
    {
      name: "Butter Fly",
      legsDetails: [
        {
          strikePrice:
            strikePrices.strikePrices.length > 0
              ? strikePrices.nearestStrikePrice
              : 0,
          optionType: 3,
          position: strategyDetails.execution,
          entryPrice: 0.0,
        },
        {
          strikePrice:
            strikePrices.strikePrices.length > 0
              ? strikePrices.nearestStrikePrice
              : 0,
          optionType: 3,
          position: strategyDetails.execution === "buy" ? "sell" : "buy",
          entryPrice: 0.0,
        },
        {
          strikePrice:
            strikePrices.strikePrices.length > 0
              ? strikePrices.nearestStrikePrice
              : 0,
          optionType: 3,
          position: strategyDetails.execution,
          entryPrice: 0.0,
        },
      ],
      type: "call",
    },
    {
      name: "Condor",
      legsDetails: [
        {
          strikePrice:
            strikePrices.strikePrices.length > 0
              ? strikePrices.nearestStrikePrice
              : 0,
          optionType: 3,
          position: strategyDetails.execution,
          entryPrice: 0.0,
        },
        {
          strikePrice:
            strikePrices.strikePrices.length > 0
              ? strikePrices.nearestStrikePrice
              : 0,
          optionType: 3,
          position: strategyDetails.execution === "buy" ? "sell" : "buy",
          entryPrice: 0.0,
        },
        {
          strikePrice:
            strikePrices.strikePrices.length > 0
              ? strikePrices.nearestStrikePrice
              : 0,
          optionType: 4,
          position: strategyDetails.execution,
          entryPrice: 0.0,
        },
        {
          strikePrice:
            strikePrices.strikePrices.length > 0
              ? strikePrices.nearestStrikePrice
              : 0,
          optionType: 4,
          position: strategyDetails.execution === "buy" ? "sell" : "buy",
          entryPrice: 0.0,
        },
      ],
    },
  ];

  const handleStrategySelectedClick = (event) => {
    setStrategyDetails((prevData) => {
      return {
        ...prevData,
        strategySelected: event.target.name,
        type: strategies[event.target.id].type ?? "",
      };
    });

    setPositions([...strategies[event.target.id].legsDetails]);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStrikePriceChange = (event, value) => {
    setPositions((prevData) => {
      var newData = [...prevData];
      newData[event.target.id.split("-")[0]].strikePrice = parseInt(value);
      return newData;
    });
  };

  const handleStrategyTypeChange = (event) => {
    setStrategyDetails((prevData) => {
      return { ...prevData, type: event.target.value };
    });

    setPositions((prevData) => {
      var newData = [...prevData];
      newData.forEach((position) => {
        position.optionType = event.target.value === "call" ? 3 : 4;
      });
      return newData;
    });
  };

  const handleSave = async () => {
    setLoading(true);
    for (let i = 0; i < positions.length; i++) {
      var instrumentDetails = optionInstrumentsList.find((instrument) => {
        return (
          instrument[3] === strategyDetails.instrument &&
          instrument[16] ===
            moment(strategyDetails.expiry, "DD MMM YYYY")
              .format("YYYY-MM-DDT14:30:00")
              .toString() &&
          instrument[17] === positions[i].strikePrice.toString() &&
          instrument[18] === positions[i].optionType.toString()
        );
      });

      if (instrumentDetails !== undefined) {
        var quotes = await getQuote(2, instrumentDetails[1]).then((response) =>
          response.json()
        );

        setPositions((prevData) => {
          var newData = [...prevData];
          newData[i].entryPrice =
            quotes.result.listQuotes.length !== 0
              ? JSON.parse(quotes.result.listQuotes[0]).LastTradedPrice
              : "N/A";
          return newData;
        });
      }
    }

    setStrategyDetails((prevData) => {
      return { ...prevData, positions: positions };
    });

    setLoading(false);

    setOpen(false);
  };

  const getNearestStrikePrice = (strikePrices, ltp) => {
    var closestStrikePrice = strikePrices[0];
    if (strikePrices.length > 0) {
      closestStrikePrice = strikePrices.reduce((a, b) => {
        return Math.abs(parseFloat(b) - ltp) < Math.abs(parseFloat(a) - ltp)
          ? b
          : a;
      });
    }
    return closestStrikePrice;
  };

  useEffect(() => {
    async function getData() {
      var selectedInstrumentList = [];

      selectedInstrumentList = optionInstrumentsList.filter(
        (item) => item[3] === strategyDetails.instrument
      );

      var equityInstrumentDetails = await getEquitySymbol(
        selectedInstrumentList[0][5] === "OPTIDX" ? "SPOT" : "EQ",
        selectedInstrumentList[0][15]
      );

      var quote = null;
      try {
        const response = await getQuote(
          1,
          equityInstrumentDetails.result[0].ExchangeInstrumentID
        );
        quote = await response.json();
        if (response.status === 400) {
          throw new Error(quote.description);
        }
      } catch (error) {
        quote = null;
      }

      var ltp = null;

      if (quote && quote?.result?.listQuotes[0] != undefined)
        ltp = JSON.parse(quote?.result?.listQuotes[0])?.LastTradedPrice;
      else ltp = null;

      var _strikePricesList = selectedInstrumentList
        .map((item) => parseInt(item[17]))
        .filter((value, index, self) => self.indexOf(value) === index);

      _strikePricesList = _strikePricesList.sort((a, b) => a - b);
      var _nearestStrikePrice = getNearestStrikePrice(_strikePricesList, ltp);
      setStrikePrices({
        strikePrices: _strikePricesList,
        nearestStrikePrice: _nearestStrikePrice,
      });

      setInstrumentSummary((prevData) => {
        return {
          ...prevData,
          InstrumentId: equityInstrumentDetails.result[0].ExchangeInstrumentID,
          Name: equityInstrumentDetails.result[0].DisplayName,
          SpotPrice: ltp,
          ExpirySelected: strategyDetails.expiry,
          Execution: strategyDetails.execution,
        };
      });
    }

    getData();
  }, []);

  return (
    <div className="flex w-full gap-5">
      <div className="flex flex-col w-3/4 gap-5 overflow-y-auto">
        <h1 className="font-medium text-lg">Select Strategy</h1>
        <div className="flex gap-5">
          {strategies.map((strategy, idx) => {
            return (
              <OutlinedButton
                key={idx}
                id={idx}
                variant="outlined"
                name={strategy.name}
                onClick={handleStrategySelectedClick}
              >
                {strategy.name}
              </OutlinedButton>
            );
          })}
        </div>
        {positions.length > 0 && (
          <Dialog
            open={open}
            fullWidth
            maxWidth="xs"
            sx={{ "& .MuiDialogContent-root": { padding: "1em 3em" } }}
          >
            <DialogTitle sx={{ fontFamily: "Poppins", fontWeight: 600 }}>
              Configure Legs
            </DialogTitle>
            <DialogContent dividers>
              <div className="w-full flex flex-col gap-7 items-center justify-center">
                {strategyDetails.strategySelected === "Spread" ||
                strategyDetails.strategySelected === "Butter Fly" ? (
                  <RadioGroup
                    row
                    value={strategyDetails.type}
                    onChange={handleStrategyTypeChange}
                  >
                    <FormControlLabel
                      value="call"
                      disableTypography
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": { color: "#643FBD" },
                          }}
                          size="small"
                        />
                      }
                      label="Call"
                    />
                    <FormControlLabel
                      value="put"
                      disableTypography
                      control={
                        <Radio
                          sx={{ "&.Mui-checked": { color: "#643FBD" } }}
                          size="small"
                        />
                      }
                      label="Put"
                    />
                  </RadioGroup>
                ) : (
                  ""
                )}
                {positions.map((position, idx) => {
                  return (
                    <div key={idx} className="flex items-center gap-7 w-full">
                      <h1 className="font-semibold text-lg">
                        {position.position === "buy" ? "+" : "-"}
                        {strategyDetails.lots}X
                      </h1>
                      <Autocomplete
                        value={position.strikePrice.toString()}
                        id={`${idx}`}
                        openOnFocus
                        disableClearable
                        size="small"
                        options={strikePrices.strikePrices.map((strikePrice) =>
                          strikePrice.toString()
                        )}
                        onChange={handleStrikePriceChange}
                        clearOnBlur
                        fullWidth
                        filterOptions={filterOptions}
                        noOptionsText="No strike price"
                        renderInput={(params) => (
                          <TextInput
                            {...params}
                            placeholder="Select a strike price"
                            helperText={`Select ${
                              position.position === "buy" ? "Long" : "Short"
                            } strike`}
                          />
                        )}
                      />
                      <h1 className="font-semibold text-lg">
                        {position.optionType === 3 ? "CE" : "PE"}
                      </h1>
                    </div>
                  );
                })}
              </div>
            </DialogContent>
            <DialogActions>
              <ColoredButton
                variant="contained"
                size="small"
                onClick={handleSave}
              >
                Save
              </ColoredButton>
              <ColoredButton
                variant="contained"
                size="small"
                onClick={handleClose}
              >
                Close
              </ColoredButton>
            </DialogActions>
          </Dialog>
        )}
        {strategyDetails.strategySelected !== "Select a strategy" &&
        strategyDetails.positions.length > 0 &&
        !open ? (
          <div className="pr-8">
            <h1 className="font-medium text-lg">
              {strategyDetails.execution === "buy" ? "Long" : "Short"}{" "}
              {strategyDetails.strategySelected} - Strategy Positions
            </h1>
            <StrategyPositions
              strategyDetails={strategyDetails}
              toggleDialog={setOpen}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="w-1/4 overflow-y-auto self-center">
        <StrategySummary
          title="Instrument Summary"
          summary={instrumentSummary}
        />
      </div>
      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
        <Loading />
      </Backdrop>
    </div>
  );
}

export default AddStrategies;
