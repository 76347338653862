import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React from "react";
import OutlinedButton from "./OutlinedButton";
import TextInput from "./TextInput";

function EditStrategyDialog({ dialogOpen, setDialogOpen }) {
  return (
    <Dialog open={dialogOpen} fullWidth maxWidth="sm">
      <DialogTitle sx={{ fontFamily: "Poppins", color: "#643FBD" }}>
        Edit Strategy
      </DialogTitle>
      <DialogContent dividers sx={{ fontFamily: "Poppins" }}>
        <Grid container columnSpacing={6} rowSpacing={3}>
          <Grid item xs={6}>
            <div className="flex flex-col gap-4">
              <label className="font-medium text-base">Strategy name</label>
              <TextInput size="small" />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="flex flex-col gap-4">
              <label className="font-medium text-base">Instrument</label>
              <p>BANKNIFTY</p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="flex flex-col gap-4">
              <label className="font-medium text-base">Expiry Date</label>
              <TextInput size="small" />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="flex flex-col gap-4">
              <label className="font-medium text-base">No. of lots</label>
              <TextInput size="small" type="number" />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className="flex flex-col gap-4">
              <label className="font-medium text-base">Target Delta</label>
              <TextInput size="small" type="number" />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="flex flex-col gap-4">
              <label className="font-medium text-base">StopLoss Delta</label>
              <TextInput size="small" type="number" />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <OutlinedButton>
          <span className="font-semibold">Save</span>
        </OutlinedButton>
        <OutlinedButton onClick={() => setDialogOpen(false)}>
          <span className="font-semibold">Close</span>
        </OutlinedButton>
      </DialogActions>
    </Dialog>
  );
}

export default EditStrategyDialog;
