import {
  Alert,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Slide,
  Snackbar,
} from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import { CheckBox } from "@mui/icons-material";
import OutlinedButton from "./OutlinedButton";
import Loading from "./Loading";
import axiosInstance from "../Configuration/axiosConfig";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BrokerSelectDialog({ open, setOpen, handleConfirmClick }) {
  const [brokersSetup, setBrokersSetup] = useState([]);

  const [totp, setTotp] = useState("");

  const [loading, setLoading] = useState(false);

  const [displayMsg, setDisplayMsg] = useState({
    type: "success",
    msg: "",
  });

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleBrokerLogin = (event) => {
    if (event.target.name === "finvasia") handleFinvasiaBrokerLogin();
  };

  const handleFinvasiaBrokerLogin = async () => {
    setLoading(true);
    try {
        const response = await axiosInstance.post(`trades/shoonya/login?totp=${totp}`);

        if(response.status === 201) {
            setSnackBarOpen(true);
            setDisplayMsg({msg: "Finvasia broker login successful", type: "success"});
        }

    } catch(err) {
        setSnackBarOpen(true);
        setDisplayMsg({msg: err.message, type: "error"});

    } finally {
        setLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      setSnackBarOpen(false);
      return;
    }

    setSnackBarOpen(false);
  };

  useEffect(() => {
    async function getBrokersSetup() {
      try {
            const response = await axiosInstance.post('auth/user/brokers/');
            if(response.status === 200)
                setBrokersSetup(response.data);
        } catch(err) {
            // Implement new err mechanism in this page
            console.log(err);
        }
    }
    getBrokersSetup();
  }, []);

  return (
    <Dialog open={open} TransitionComponent={Transition} onClose={handleClose}>
      <DialogTitle
        sx={{ fontFamily: "Poppins", color: "#643FBD", fontWeight: "bold" }}
      >
        Select broker(s) to execute the strategy
      </DialogTitle>
      <DialogContent dividers>
        <div className="flex flex-col gap-5">
          {brokersSetup &&
            Object.entries(brokersSetup).map((value, key) => {
              return (
                <div key={key} className="flex justify-between">
                  <FormControlLabel
                    label={value[0].charAt(0).toUpperCase() + value[0].slice(1)}
                    control={
                      <CheckBox
                        defaultChecked
                        disabled={!value[1].logged_in}
                        size="small"
                        sx={{
                          color: "#643FBD",
                          "&.Mui-checked": {
                            color: "#643FBD",
                          },
                        }}
                      />
                    }
                  />
                  {!value[1].logged_in && (
                    <OutlinedButton
                      name={value[0]}
                      variant="outlined"
                      size="small"
                      onClick={handleBrokerLogin}
                    >
                      Login
                    </OutlinedButton>
                  )}
                </div>
              );
            })}
        </div>
      </DialogContent>
      <DialogActions>
        <OutlinedButton onClick={handleConfirmClick}>Confirm</OutlinedButton>
        <OutlinedButton onClick={handleClose}>Cancel</OutlinedButton>
      </DialogActions>

      <Backdrop sx={{ color: "#fff", zIndex: 1201 }} open={loading}>
        <Loading />
      </Backdrop>

      <Snackbar
        open={snackBarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert
          variant="filled"
          onClose={handleSnackbarClose}
          severity={displayMsg.type}
          sx={{ width: "100%" }}
        >
          {displayMsg.msg}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default BrokerSelectDialog;
